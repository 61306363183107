import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const OpenCases = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="open-cases">
      <div
        className="div-7"
        style={{
          height: screenWidth < 900 ? "800px" : screenWidth >= 900 ? "1080px" : undefined,
          overflow: screenWidth < 900 ? "hidden" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100% " : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-29">
            <img className="vector-12" alt="Vector" src="./Img/vector_background.svg" />
            <div className="ellipse-47" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="text-wrapper-136">Open cases</div>
            <div className="frame-218 scrollable-area-mobile">
              <div className="frame-219">
                <div className="frame-220">
                  <div className="text-wrapper-137">All cases</div>
                  <div className="frame-221">
                    <div className="chevron-down-wrapper">
                      <ChevronDownWrapper />
                    </div>
                    <div className="frame-222">
                      <div className="text-wrapper-138">View all</div>
                    </div>
                    <div className="chevron-down-instance-wrapper">
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="frame-219">
                <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="./Img/open-cases-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="./Img/open-cases-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases-4.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases-5.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                      <div className="frame-228">
                        <div className="text-wrapper-139">NEW</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-219">
                <div className="frame-220">
                  <div className="text-wrapper-137">Other cases</div>
                  <div className="frame-221">
                    <div className="frame-229">
                      <ChevronDownWrapper />
                    </div>
                    <div className="frame-222">
                      <div className="text-wrapper-138">View all</div>
                    </div>
                    <div className="chevron-down-instance-wrapper">
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="frame-219">
                  <div className="frame-220">
                  
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="./Img/open-cases-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-230" href="/open-case">
                          <div className="text-wrapper-143">$54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-230" href="/open-case">
                          <div className="text-wrapper-143">$54,5</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="./Img/open-cases-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-227" href="/open-case">
                          <img className="storage-18" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-142">54,5</div>
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases-4.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-230" href="/open-case">
                          <div className="text-wrapper-143">$54,5</div>
                        </a>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="./Img/open-cases-5.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <a className="frame-230" href="/open-case">
                          <div className="text-wrapper-143">$54,5</div>
                        </a>
                      </div>
                      <div className="frame-228">
                        <div className="text-wrapper-139">NEW</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-30 scrollable-area">
              <img className="vector-13" alt="Vector" src="./Img/vector_background.svg" />
              <div className="ellipse-49" />
              <div className="frame-254">
                <div className="frame-238">
                  <div className="text-wrapper-156">Open cases</div>
                </div>
                <div className="frame-255">
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">All cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-260">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>

                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                        <div className="frame-265">
                          <div className="text-wrapper-153">POPULAR</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-5.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-162">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">Other cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-269">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                        <div className="frame-270">
                          <div className="text-wrapper-153">NEW</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">Other cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-269">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                        <div className="frame-270">
                          <div className="text-wrapper-153">NEW</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <a className="frame-264" href="/open-case">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* <div className="frame-271">
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax bold setting" src="/img/vuesax-bold-setting-2-8.png" />
                    <div className="text-wrapper-164">Settings</div>
                  </div>
                </div>
                <div className="frame-274">
                  <div className="frame-239">
                    <div className="img-11">
                      <div className="gamepad" />
                    </div>
                    <div className="text-wrapper-165">Game history</div>
                  </div>
                </div>
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax bold receipt" src="/img/vuesax-bold-receipt-2.png" />
                    <div className="text-wrapper-164">Transactions</div>
                  </div>
                </div>
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax linear logout" src="/img/vuesax-linear-logout-3.png" />
                    <div className="text-wrapper-166">Log Out</div>
                  </div>
                </div>
              </div> */}
              <div className="rectangle-23" />
            </div>
            
            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
