import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const OpenCase = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="open-case">
      <div
        className="open-cases-2"
        style={{
          height: screenWidth < 900 ? "1200px" : screenWidth >= 900 ? "1150px" : undefined,
          overflow: screenWidth >= 900 ? "hidden" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >


        <div
          className="overlap-18"
          style={{
            height: screenWidth >= 900 ? "1078px" : screenWidth < 900 ? "323px" : undefined,
            width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "375px" : undefined,
          }}
        >
          {screenWidth >= 900 && <img className="vector-9" alt="Vector" src="./Img/vector_background.svg" />}

          <div
            className="ellipse-34"
            style={{
              borderRadius: screenWidth >= 900 ? "205px" : screenWidth < 900 ? "168px/49px" : undefined,
              height: screenWidth >= 900 ? "410px" : screenWidth < 900 ? "98px" : undefined,
              left: screenWidth >= 900 ? "867px" : screenWidth < 900 ? "19px" : undefined,
              top: screenWidth >= 900 ? "23px" : screenWidth < 900 ? "87px" : undefined,
              width: screenWidth >= 900 ? "410px" : screenWidth < 900 ? "100%" : undefined,
            }}
          />
          {screenWidth >= 900 && (
            <>
              <div className="frame-145">
                <div className="frame-146">
                  <div className="frame-147">
                    <div className="frame-148">
                      <div className="text-wrapper-95">Case Name</div>
                      <div className="frame-149">
                        <div className="image-wrapper">
                          <img className="image-6" alt="Image" src="./Img/open-cases.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-150">
                    <div className="frame-151">
                      <div className="frame-152">
                        <div className="text-wrapper-96">X1</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X2</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X3</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X4</div>
                      </div>
                      <div className="frame-153">
                        <div className="text-wrapper-98">Open for</div>
                        <div className="frame-154">
                          <img className="img-7" alt="Storage" src="./Img/storage-open-cases.svg" />
                          <div className="text-wrapper-99">5.5</div>
                        </div>
                      </div>
                      <div className="frame-155">
                        <div className="text-wrapper-100">Fast open</div>
                        <img className="img-7" alt="Vuesax bold flash" src="./Img/flash.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-156">
                  <div className="frame-157">
                    <div className="text-wrapper-101">Case contents</div>
                    <p className="text-wrapper-102">All items that may fall out of your case</p>
                  </div>
                  <div className="frame-158">
                    <div className="frame-159">
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="./img/object_1.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="./img/object_2.png"/>
                        </div>
                      </div>
                      <div className="frame-164">
                        <div className="overlap-19">
                          <div className="ellipse-37" />
                          <img className="vector-11" alt="Vector" src="./Img/compass.svg" />
                          <img className="image-8" alt="Image" src="./img/object_3.png" />
                          <div className="frame-165">
                            <div className="text-wrapper-106">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image"  src="./img/object_4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image"  src="./img/object_1.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image"  src="./img/object_2.png" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-159">
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image"  src="./img/object_2.png" />
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image"  src="./img/object_1.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image"  src="./img/object_2.png" />
                        </div>
                      </div>
                      <div className="frame-167">
                        <div className="overlap-group-20">
                          <div className="ellipse-39" />
                          <img className="image-9" alt="Image"  src="./img/object_3.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image"  src="./img/object_4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image"  src="./img/object_1.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-168">
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image"  src="./img/object_2.png" />
                        </div>
                      </div>
                      <div className="frame-167">
                        <div className="overlap-group-20">
                          <div className="ellipse-39" />
                          <img className="image-9" alt="Image"  src="./img/object_3.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image"  src="./img/object_4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image"  src="./img/object_1.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {screenWidth < 900 && (
            <>
              <div className="text-wrapper-112">Case Name</div>
              <div className="frame-178">
                <div className="overlap-group-21">
                  <img className="image-10" alt="Image" src="./Img/open-cases-5.png" />
                </div>
              </div>
              

            </>
          )}
        </div>
        {screenWidth >= 900 && (
          <>
            <div className="frame-187">
              <img
                className="vuesax-linear-arrow-4"
                alt="Vuesax linear arrow"
                src="./Img/arrow-left.svg"
              />
              <div className="text-wrapper-117">Go back</div>
            </div>
            <LiveChat />
          </>
        )}

        {screenWidth < 900 && (
          <>
            <div className="overlap-26">
              <div className="rectangle-15" />
              <div className="frame-202">
                <div className="frame-203">
                  <div className="text-wrapper-125">Open for</div>
                  <div className="frame-154">
                    <img className="img-8" alt="Storage" src="./img/storage.svg" />
                    <div className="text-wrapper-126">5.5</div>
                  </div>
                </div>
                <div className="frame-204">
                  <div className="navbar">
                    <div className="text-wrapper-127">X1</div>
                     |
                    <div className="text-wrapper-128">X2</div>
                     
                    <div className="text-wrapper-128">X3</div>
                     
                    <div className="text-wrapper-128">X4</div>
                  </div>
                  <div className="frame-205">
                    <div className="text-wrapper-129">Fast</div>
                    <img className="vuesax-bold-flash" alt="Vuesax bold flash" src="./Img/flash.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-27">
              <div className="frame-206">
                <div className="frame-207">
                  <div className="text-wrapper-130">Case contents</div>
                  <p className="text-wrapper-131">All items that may fall out of your case</p>
                </div>
                <div className="frame-208">
                  <div className="frame-159">
                    <div className="frame-209">
                      <div className="overlap-group-26">
                        <div className="ellipse-43" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Clothing</div>
                          <div className="text-wrapper-132">Snowcamo Jacket</div>
                        </div>
                        <img className="image-11" alt="Image"  src="./img/object_2.png" />
                      </div>
                    </div>
                    <div className="frame-210">
                      <div className="overlap-group-26">
                        <div className="ellipse-44" />
                        <img className="image-12" alt="Image"  src="./img/object_3.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Weapon</div>
                          <div className="text-wrapper-132">Tempered AK47</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-211">
                      <div className="overlap-group-26">
                        <div className="ellipse-45" />
                        <img className="image-12" alt="Image"  src="./img/object_4.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Misc</div>
                          <div className="text-wrapper-132">High Quality Bag</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-212">
                      <div className="overlap-group-26">
                        <div className="ellipse-46" />
                        <img className="image-11" alt="Image"  src="./img/object_1.png" />
                        <div className="frame-161">
                          <div className="text-wrapper-103">Resource</div>
                          <div className="text-wrapper-133">Cloth</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-209">
                      <div className="overlap-group-26">
                        <div className="ellipse-43" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Clothing</div>
                          <div className="text-wrapper-132">Snowcamo Jacket</div>
                        </div>
                        <img className="image-11" alt="Image"  src="./img/object_2.png" />
                      </div>
                    </div>
                    <div className="frame-210">
                      <div className="overlap-group-26">
                        <div className="ellipse-44" />
                        <img className="image-12" alt="Image"  src="./img/object_3.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Weapon</div>
                          <div className="text-wrapper-132">Tempered AK47</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-211">
                      <div className="overlap-group-26">
                        <div className="ellipse-45" />
                        <img className="image-12" alt="Image"  src="./img/object_4.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Misc</div>
                          <div className="text-wrapper-132">High Quality Bag</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-212">
                      <div className="overlap-group-26">
                        <div className="ellipse-46" />
                        <img className="image-11" alt="Image"  src="./img/object_1.png" />
                        <div className="frame-161">
                          <div className="text-wrapper-103">Resource</div>
                          <div className="text-wrapper-133">Cloth</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}
      </div>
    </div>
  );
};
