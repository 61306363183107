import React from "react";
import { SelectBox } from "react-select";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import "./style.css";


// Función para alternar el menú


export const HeaderMobile = () => {
    const screenWidth = useWindowWidth();

    return (
        <div className="headerMobile">
            <div className="div-header"
                style={{
                    height: screenWidth < 900 ? "70px" : screenWidth >= 900 ? "70px" : undefined,
                    overflow: screenWidth < 900 ? "hidden" : undefined,
                    position: screenWidth < 900 ? "relative" : undefined,
                    width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
                }}
            >
                {screenWidth < 900 && (
                    <>
                        <div className="frame-960">
                            <div className="overlap-104">
                                <div className="frame-961">
                                    <a className="frame-963" href="/">
                                        <img className="img-37" alt="Home" src="./Img/Home.svg" />
                                        <div className="text-wrapper-569">Home</div>
                                    </a>
                                    <div className="frame-963">
                                        <img className="img-37" alt="Gameboy" src="./Img/gameboy.svg" />
                                        <div className="text-wrapper-569">Games</div>
                                    </div>
                                    <a className="frame-963" href="/LiveChat">
                                        <img className="img-37" alt="Chatting" src="./Img/chatting.svg" />
                                        <div className="text-wrapper-569">Chat</div>
                                    </a>
                                    <div className="frame-964">
                                        <img className="img-37" alt="Vuesax bold add" src="./Img/plus-green-mobile.svg" />
                                        <div className="text-wrapper-570">Sign In</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                    </>
                )}
            </div>
        </div>
    )
};
