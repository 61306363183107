import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const Transactions = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="transactions">
      <div
        className="div-10"
        style={{
          height: screenWidth < 900 ? "1200px" : screenWidth >= 900 ? "1080px" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth >= 900 && (
          <>
            <div className="frame-401">
              <div className="overlap-43">
                <img className="vector-25" alt="Vector" src="./Img/vector-settings.svg" />
                <div className="frame-402">
                  <div className="frame-403">
                    <div className="overlap-group-63">
                      <img className="rectangle-33" alt="Rectangle" src="./Img/john_watson.png" />
                      <div className="rectangle-34" />
                    </div>
                  </div>
                  <div className="frame-404">
                    <div className="frame-405">
                      <div className="text-wrapper-238">John Watson</div>
                      <p className="text-wrapper-239">Member since July 5 2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-406">
              <div className="div-wrapper-2">
                <div className="vuesax-bold-card-2">
                  <div className="card-tick-2">
                  </div>
                </div>
              </div>
              <div className="frame-407">
                <div className="frame-408">
                  <div className="text-wrapper-240">Deposited</div>
                </div>
                <div className="frame-409">
                  <img className="img-16" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-241">789</div>
                </div>

              </div>

              <div className="frame-407">
                <div className="div-wrapper-2">
                  <div className="overlap-group-65">
                  </div>
                </div>
                <div className="text-wrapper-242">Withdrawn</div>
                <div className="frame-409">
                  <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                  <div className="text-wrapper-243">789</div>
                </div>
              </div>

              <div className="frame-407">
                <div className="frame-411">
                  <div className="overlap-group-66">
                  </div>
                </div>
                <div className="text-wrapper-240">Wagered</div>
                <div className="frame-410">
                  <img className="img-17" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-241">35,5</div>
                </div>
              </div>

              <div className="frame-407">
                <div className="frame-412">
                  <div className="overlap-group-67">
                  </div>
                </div>
                <div className="text-wrapper-240">Total</div>
                <div className="frame-410">
                  <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                  <p className="element-5">
                    <span className="text-wrapper-244">+</span>
                    <span className="text-wrapper-245">&nbsp;</span>
                    <span className="text-wrapper-244">789</span>
                  </p>
                </div>

              </div>
            </div>
          </>
        )}

        <div
          className="overlap-44"
          style={{
            height: screenWidth >= 900 ? "1023px" : screenWidth < 900 ? "1200px" : undefined,
            left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "0" : undefined,
            top: screenWidth >= 900 ? "70px" : screenWidth < 900 ? "45px" : undefined,
            width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "100%" : undefined,
          }}
        >




          <div
            className="frame-415"
            style={{
              gap: screenWidth >= 900 ? "32px" : screenWidth < 900 ? "24px" : undefined,
              left: screenWidth >= 900 ? "20px" : screenWidth < 900 ? "2%" : undefined,
              top: screenWidth >= 900 ? "367px" : screenWidth < 900 ? "0" : undefined,
              width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "96%" : undefined,
            }}
          >
            {screenWidth >= 900 && (
              <div className="frame-416">
                <div className="group-30 scrollable-area">
                <div className="frame-417">
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="storage-24" alt="Storage" src="/img/storage-5634434-1-130.png" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-417">
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="img-17" alt="Storage" src="./Img/storage-disabled.svg" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-418">
                      <div className="frame-419">
                        <div className="frame-420">
                          <img className="frame-421" alt="Frame" src="./Img/plus-green.svg" />
                          <div className="text-wrapper-247">Deposit</div>
                        </div>
                        <img className="logo-15" alt="Logo" src="./Img/mastercard.svg" />
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">20 sec ago</div>
                      </div>
                      <div className="frame-423">
                        <div className="frame-410">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-249">+ 550</div>
                        </div>
                        <p className="element-6">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-424">
                          <div className="frame-425">
                            <div className="text-wrapper-252">Done</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-426">
                      <div className="frame-427">
                        <div className="frame-428">
                          <img className="frame-421" alt="Frame" src="./Img/minus-gray.svg" />
                          <div className="text-wrapper-247">Withdrawal</div>
                        </div>
                        <div className="group-31">
                        <img className="logo-15" alt="Logo" src="./Img/usdt.svg" />
                        </div>
                      </div>
                      <div className="frame-422">
                        <div className="text-wrapper-248">25 June</div>
                      </div>
                      <div className="frame-429">
                        <div className="frame-430">
                          <img className="storage-24" alt="Storage" src="/img/storage-5634434-1-130.png" />
                          <div className="text-wrapper-253">1.200</div>
                        </div>
                        <p className="element-7">
                          <span className="text-wrapper-250">5% </span>
                          <span className="text-wrapper-251">($20)</span>
                        </p>
                        <div className="frame-431">
                          <div className="frame-425">
                            <div className="text-wrapper-254">In process</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-432">
                        <img className="image-19" alt="Image" src="./Img/object_1.png" />
                        <img className="image-19" alt="Image" src="./Img/object_2.png" />
                        <img className="image-20" alt="Image" src="./Img/object_3.png" />
                      </div>
                    </div>
                  </div>
                  <div className="group-33">
                    <div className="text-wrapper-255">Type</div>
                    <div className="text-wrapper-256">ITEM</div>
                    <div className="text-wrapper-257">Time</div>
                    <div className="text-wrapper-258">Sum</div>
                    <div className="text-wrapper-259">Method</div>
                    <div className="text-wrapper-260">Fee</div>
                    <div className="text-wrapper-261">Status</div>
                  </div>
                </div>
              </div>
            )}

            {screenWidth < 900 && (
              <>
                <div className="frame-433">
                  <div className="frame-434">
                    <div className="overlap-45">
                      <div className="ellipse-76" />
                      <div className="ellipse-77" />
                      <div className="ellipse-78" />
                      <div className="frame-435">
                        <div className="frame-403">
                          <div className="overlap-group-63">
                            <img className="rectangle-33" alt="Rectangle" src="./Img/john_watson.png" />
                            <div className="rectangle-34" />
                          </div>
                        </div>
                        <div className="frame-404">
                          <div className="frame-405">
                            <div className="text-wrapper-238">John Watson</div>
                            <p className="text-wrapper-239">Member since July 5 2022</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-11">
                    <div className="frame-436">
                      <div className="frame-437">
                        <div className="frame-408">
                          <div className="text-wrapper-240">Deposited</div>
                        </div>
                        <div className="frame-409">
                          <img className="img-16" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-241">789</div>
                        </div>
                        <div className="div-wrapper-3">
                          <div className="vuesax-bold-card-3">
                            <div className="card-tick-3">
                              <img className="vector-31" alt="Vector" src="/img/vector-11.png" />
                              <div className="overlap-group-68">
                                <img className="vector-32" alt="Vector" src="/img/vector-12.png" />
                                <div className="ellipse-79" />
                                <img className="vector-33" alt="Vector" src="/img/vector-7-4.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-437">
                        <div className="text-wrapper-262">Withdrawn</div>
                        <div className="frame-438">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-243">789</div>
                        </div>
                        <div className="div-wrapper-3">
                          <div className="overlap-group-69">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-436">
                      <div className="frame-437">
                        <div className="text-wrapper-263">Wagered</div>
                        <div className="frame-439">
                          <img className="img-17" alt="Storage" src="./img/storage.svg" />
                          <div className="text-wrapper-241">35,5</div>
                        </div>
                        <div className="frame-440">
                          <div className="overlap-group-70">
                          </div>
                        </div>
                      </div>
                      <div className="frame-437">
                        <div className="text-wrapper-263">Total</div>
                        <div className="frame-439">
                          <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                          <p className="element-5">
                            <span className="text-wrapper-244">+</span>
                            <span className="text-wrapper-245">&nbsp;</span>
                            <span className="text-wrapper-244">789</span>
                          </p>
                        </div>
                        <div className="frame-441">
                          <div className="overlap-group-71">
                            <div className="rectangle-37" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="line-16" alt="Line" src="/img/line-1-5.png" />
                <div className="frame-442">
                  <div className="frame-436">
                    <a className="frame-445" href="/settings">
                      <img className="img-18" alt="Vuesax bold setting" src="./Img/settings-gray.svg" />
                      <div className="text-wrapper-264">Settings</div>
                    </a>
                    <a className="frame-445" href="/game-history">
                      <div className="img-18">
                        <div className="gamepad-3" />
                      </div>
                      <div className="text-wrapper-265">Game history</div>
                    </a>
                    <a className="frame-443" href="/transactions">
                      <img className="img-18" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                      <div className="text-wrapper-265">Transactions</div>
                    </a>
                  </div>
                  <div className="frame-433">
                    <div className="div-11">
                      <div className="frame-446">
                        <div className="frame-447">
                          <div className="text-wrapper-266">All</div>
                        </div>
                        <div className="frame-447">
                          <div className="text-wrapper-267">From latest</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-433 scrollable-area-mobile">
                      <div className="frame-448">
                      <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-458">
                            <img className="image-19" alt="Image" src="./Img/object_1.png" />
                            <img className="image-19" alt="Image" src="./Img/object_2.png" />
                            <img className="image-20" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/plus-green.svg" />
                                <div className="text-wrapper-268">Deposit</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-453">
                              <div className="frame-425">
                                <div className="text-wrapper-269">Done</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-449">
                          <div className="frame-450">
                            <div className="frame-451">
                              <div className="frame-452">
                                <img className="img-17" alt="Frame" src="./Img/minus-gray.svg" />
                                <div className="text-wrapper-268">Withdrawal</div>
                              </div>
                              <div className="text-wrapper-267">20 sec.</div>
                            </div>
                            <div className="frame-459">
                              <div className="frame-425">
                                <div className="text-wrapper-274">In process</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-454">
                            <div className="frame-455">
                              <div className="text-wrapper-270">Sum</div>
                              <div className="frame-456">
                                <img className="img-17" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-268">+ 789</div>
                              </div>
                            </div>
                            <div className="frame-455">
                              <div className="text-wrapper-271">Fee</div>
                              <p className="element-8">
                                <span className="text-wrapper-272">5% </span>
                                <span className="text-wrapper-273">($20)</span>
                              </p>
                            </div>
                            <div className="frame-457">
                              <div className="text-wrapper-271">Method</div>
                              <div className="group-34">
                                 
                                 <img className="vector-35" alt="Vector" src="./Img/usdt.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="rectangle-38"
            style={{
              height: screenWidth >= 900 ? "241px" : screenWidth < 900 ? "167px" : undefined,
              top: screenWidth >= 900 ? "769px" : screenWidth < 900 ? "1129px" : undefined,
              width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "373px" : undefined,
            }}
          />
          {screenWidth < 900 && (
            <div className="frame-460">
              <div className="overlap-46">
                <div className="element-indicator-4">
                  <div className="line-17" />
                </div>
                <div className="frame-461">
                  <div className="frame-462">
                    <img className="img-16" alt="Home" src="/img/home-06-2.png" />
                    <div className="text-wrapper-275">Home</div>
                  </div>
                  <div className="frame-463">
                    <img className="img-16" alt="Gameboy" src="/img/gameboy-2.png" />
                    <div className="text-wrapper-276">Games</div>
                  </div>
                  <div className="frame-463">
                    <img className="img-16" alt="Chatting" src="/img/chatting-01.png" />
                    <div className="text-wrapper-276">Chat</div>
                  </div>
                  <div className="frame-463">
                    <div className="img-16">
                      <div className="frame-464">
                        <img className="rectangle-39" alt="Rectangle" src="/img/rectangle-1-1.png" />
                      </div>
                    </div>
                    <div className="text-wrapper-276">Settings</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {screenWidth >= 900 && (
          <>
            <div className="frame-465">
              <div className="frame-451">
                <a className="frame-466" href="/settings">
                  <img className="img-19" alt="Vuesax bold setting" src="./Img/settings-gray.svg" />
                  <div className="text-wrapper-277">Settings</div>
                </a>
                <a className="frame-466" href="/game-history">
                  <div className="img-19">
                    <div className="gamepad-4" />
                  </div>
                  <div className="text-wrapper-277">Game history</div>
                </a>
                <a className="frame-467" href="/transactions">
                  <img className="img-19" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                  <div className="text-wrapper-278">Transactions</div>
                </a>
              </div>
              <div className="frame-468">
                <div className="frame-469">
                  <div className="text-wrapper-248">All</div>
                  <ChevronDownWrapper />
                </div>
                <div className="frame-469">
                  <div className="text-wrapper-279">From latest</div>
                  <ChevronDownWrapper />
                </div>
              </div>
            </div>
            <LiveChat />

          </>
        )}

1
      </div>
    </div>
  );
};
