import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const Settings = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="settings">
      <div
        className="div-16 scrollable-area"
        style={{
          height: screenWidth < 900 ? "1200px" : screenWidth >= 900 ? "1080px" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth >= 900 && (
          <>
            <div className="frame-593">
              <div className="overlap-63">
                <img className="vector-45" alt="Vector" src="./Img/vector-settings.svg" />
                <div className="frame-594">
                  <div className="frame-595">
                    <div className="overlap-group-103">
                      <img className="rectangle-51" alt="John Watson" src="./Img/john_watson.png" />
                      <div className="rectangle-52" />
                    </div>
                  </div>
                  <div className="frame-596">
                    <div className="frame-597">
                      <div className="text-wrapper-344">John Watson</div>
                      <p className="text-wrapper-345">Member since July 5 2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-598">
              <a className="frame-599" href="/settings">
                <img className="img-24" alt="Vuesax bold setting" src="./Img/settings.svg" />
                <div className="text-wrapper-346">Settings</div>
              </a>
              <a className="frame-600" href="/game-history">
                <div className="img-24">
                  <div className="gamepad-7" />
                </div>
                <div className="text-wrapper-347">Game history</div>
              </a>
              <a className="frame-600" href="/transactions">
                <img className="img-24" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                <div className="text-wrapper-347">Transactions</div>
              </a>
            </div>
            <div className="frame-601">
              <div className="frame-602">
                <div className="vuesax-bold-card-6">
                  <div className="vuesax-bold-card-7">
                    <div className="card-tick-6">

                    </div>
                  </div>
                </div>
                <div className="frame-603">
                  <div className="text-wrapper-348">Deposited</div>
                </div>
                <div className="frame-604">
                  <img className="img-25" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-349">789</div>
                </div>

              </div>
              <div className="frame-602">
                <div className="text-wrapper-350">Withdrawn</div>
                <div className="frame-604">
                  <img className="img-26" alt="Storage" src="./Img/storage-green.svg" />
                  <div className="text-wrapper-351">789</div>
                </div>
                <div className="frame-605">
                  <div className="overlap-group-105">

                  </div>
                </div>
                <div className="ellipse-99" />
              </div>
              <div className="frame-602">
                <div className="text-wrapper-348">Wagered</div>
                <div className="frame-606">
                  <img className="img-26" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-349">35,5</div>
                </div>
                <div className="frame-607">
                  <div className="overlap-group-106" />

                </div>
              </div>
              <div className="frame-602">
                <div className="frame-608">
                  <div className="overlap-group-107">
                  </div>
                </div>
                <div className="text-wrapper-348">Total</div>
                <div className="frame-606">
                  <img className="img-26" alt="Storage" src="./Img/storage-green.svg" />
                  <p className="element-10">
                    <span className="text-wrapper-352">+</span>
                    <span className="text-wrapper-353">&nbsp;</span>
                    <span className="text-wrapper-352">789</span>
                  </p>
                </div>

              </div>
            </div>
          </>
        )}

        <div
          className="overlap-64 "
          style={{
            height: screenWidth >= 900 ? "1010px" : screenWidth < 900 ? "1000px" : undefined,
            left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "2%" : undefined,
            top: screenWidth >= 900 ? "70px" : screenWidth < 900 ? "45px" : undefined,
            width: screenWidth >= 900 ? "75%" : screenWidth < 900 ? "100%" : undefined,
          }}
        >

          {screenWidth < 900 && <div className="rectangle-55" />}

          <div
            className="frame-611 "
            style={{
              gap: screenWidth < 900 ? "24px" : screenWidth >= 900 ? "32px" : undefined,
              left: screenWidth < 900 ? "0" : screenWidth >= 900 ? "20px" : undefined,
              top: screenWidth < 900 ? "0" : screenWidth >= 900 ? "361px" : undefined,
              width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
            }}
          >
            {screenWidth >= 900 && (
              <>
                <div className="frame-612">
                  <div className="frame-613">
                    <div className="frame-614">
                      <div className="text-wrapper-355">Steam ID</div>
                    </div>
                    <div className="frame-615">
                      <div className="frame-616">
                        <input className=" input-text text-wrapper-356" placeholder="Your Steam ID"></input>
                        <a className="text-wrapper-357 position-right" href="#">Get ID</a>
                      </div>
                      <a className="frame-617">
                        <div className="text-wrapper-358">Save</div>
                      </a>
                    </div>
                  </div>
                  <div className="frame-613">
                    <div className="frame-614">
                      <div className="text-wrapper-355">Trade link</div>
                    </div>
                    <div className="frame-615">
                      <div className="frame-616">
                        <a
                          className="text-wrapper-359"
                          href="https://steamcommunity.com/tradeoffer/new/?partner=1105743534&amp;token=lhflQ_6o"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          https://steamcommunit...
                        </a>
                        <div className="text-wrapper-357">Change</div>
                      </div>
                      <a className="frame-617" href="#">
                        <div className="text-wrapper-358">Save</div>
                      </a>
                    </div>
                  </div>
                  <div className="frame-613">
                    <div className="frame-614">
                      <div className="text-wrapper-355">Discord</div>
                    </div>
                    <div className="frame-615">
                      <div className="frame-616">
                        <input className=" input-text text-wrapper-356" placeholder="Your discord is not linked yet"></input>

                      </div>
                      <a className="frame-619" href="https://www.discord.com">
                        <img
                          className="img-26"
                          alt="Discord icon svgrepo"
                          src="./Img/discord.svg"
                        />
                        <div className="text-wrapper-358">Link discord</div>
                      </a>
                    </div>
                  </div>
                </div>
                <img className="line-24" alt="Line" src="/img/line-3.png" />
              </>
            )}

            <div className="frame-620">
              <div
                className="frame-621"
                style={{
                  alignItems: screenWidth >= 900 ? "center" : undefined,
                  alignSelf: screenWidth < 900 ? "stretch" : undefined,
                  backgroundColor: screenWidth < 900 ? "var(--black)" : undefined,
                  borderRadius: screenWidth < 900 ? "16px" : undefined,
                  display: screenWidth >= 900 ? "inline-flex" : undefined,
                  flex: screenWidth >= 900 ? "0 0 auto" : undefined,
                  gap: screenWidth >= 900 ? "6px" : undefined,
                  height: screenWidth < 900 ? "129px" : undefined,
                  overflow: screenWidth < 900 ? "hidden" : undefined,
                  width: screenWidth < 900 ? "97%" : undefined,
                }}
              >
                {screenWidth >= 900 && (
                  <>
                    <img className="closed-lock" alt="Closed lock" src="./Img/lock-self-exclusion.png" />
                    <div className="text-wrapper-360">Self-exclusion</div>
                  </>
                )}

                {screenWidth < 900 && (
                  <div className="overlap-65">
                    <div className="ellipse-100" />
                    <div className="ellipse-101" />
                    <div className="ellipse-102" />
                    <div className="frame-622">
                      <div className="frame-595">
                        <div className="overlap-group-103">
                          <img className="rectangle-51" alt="Rectangle" src="./Img/john_watson.png" />
                          <div className="rectangle-52" />
                        </div>
                      </div>
                      <div className="frame-596">
                        <div className="frame-597">
                          <div className="text-wrapper-344">John Watson</div>
                          <p className="text-wrapper-345">Member since July 5 2022</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="frame-623"
                style={{
                  alignSelf: screenWidth < 900 ? "stretch" : undefined,
                  gap: screenWidth >= 900 ? "16px" : screenWidth < 900 ? "8px" : undefined,
                  justifyContent: screenWidth < 900 ? "center" : undefined,
                  width: screenWidth >= 900 ? "556px" : screenWidth < 900 ? "100%" : undefined,
                }}
              >
                <div
                  className="frame-624"
                  style={{
                    alignItems: screenWidth >= 900 ? "flex-start" : screenWidth < 900 ? "center" : undefined,
                    flexDirection: screenWidth >= 900 ? "column" : undefined,
                  }}
                >
                  <div
                    className="gambling-addiction"
                    style={{
                      alignItems: screenWidth < 900 ? "center" : undefined,
                      alignSelf: screenWidth >= 900 ? "stretch" : undefined,
                      background:
                        screenWidth < 900
                          ? "linear-gradient(180deg, rgb(27.7, 29.15, 30.6) 0%, rgba(28, 29, 31, 0.5) 100%)"
                          : undefined,
                      borderRadius: screenWidth < 900 ? "10px" : undefined,
                      color: screenWidth >= 900 ? "var(--secondary)" : undefined,
                      display: screenWidth < 900 ? "flex" : undefined,
                      flex: screenWidth < 900 ? "1" : undefined,
                      flexDirection: screenWidth < 900 ? "column" : undefined,
                      flexGrow: screenWidth < 900 ? "1" : undefined,
                      fontFamily: screenWidth >= 900 ? "'Qanelas-SemiBold', Helvetica" : undefined,
                      fontSize: screenWidth >= 900 ? "15px" : undefined,
                      fontWeight: screenWidth >= 900 ? "400" : undefined,
                      gap: screenWidth < 900 ? "8px" : undefined,
                      height: screenWidth < 900 ? "78px" : undefined,
                      justifyContent: screenWidth < 900 ? "center" : undefined,
                      letterSpacing: screenWidth >= 900 ? "0" : undefined,
                      lineHeight: screenWidth >= 900 ? "22.5px" : undefined,
                      marginTop: screenWidth >= 900 ? "-1.00px" : undefined,
                      overflow: screenWidth < 900 ? "hidden" : undefined,
                      padding: screenWidth < 900 ? "20px 16px" : undefined,
                    }}
                  >
                    {screenWidth >= 900 && (
                      <>
                        <p className="span-wrapper-2">
                          <span className="text-wrapper-361">Gambling addiction is serious. </span>
                          <span className="text-wrapper-363">
                            At Rust Oasis, we provide an option for you to temporarily self-exclude from our platform.
                          </span>
                        </p>
                      </>
                    )}

                    {screenWidth < 900 && (
                      <>
                      <div className="div-wrapper-5">
                          <div className="vuesax-bold-card-8">
                            <div className="card-tick-7">
                             
                            </div>
                          </div>
                        </div>
                        <div className="frame-603">
                          <div className="text-wrapper-348">Deposited</div>
                        </div>
                        <div className="frame-604">
                          <img className="img-25" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-349">789</div>
                        </div>
                        
                      </>
                    )}
                  </div>
                  <div
                    className="during-this-period"
                    style={{
                      alignItems: screenWidth < 900 ? "center" : undefined,
                      alignSelf: screenWidth >= 900 ? "stretch" : undefined,
                      background:
                        screenWidth < 900
                          ? "linear-gradient(180deg, rgb(27.7, 29.15, 30.6) 0%, rgba(28, 29, 31, 0.5) 100%)"
                          : undefined,
                      borderRadius: screenWidth < 900 ? "10px" : undefined,
                      color: screenWidth >= 900 ? "transparent" : undefined,
                      display: screenWidth < 900 ? "flex" : undefined,
                      flex: screenWidth < 900 ? "1" : undefined,
                      flexDirection: screenWidth < 900 ? "column" : undefined,
                      flexGrow: screenWidth < 900 ? "1" : undefined,
                      fontFamily: screenWidth >= 900 ? "'Qanelas-Medium', Helvetica" : undefined,
                      fontSize: screenWidth >= 900 ? "14px" : undefined,
                      fontWeight: screenWidth >= 900 ? "400" : undefined,
                      gap: screenWidth < 900 ? "8px" : undefined,
                      height: screenWidth < 900 ? "78px" : undefined,
                      justifyContent: screenWidth < 900 ? "center" : undefined,
                      letterSpacing: screenWidth >= 900 ? "0" : undefined,
                      lineHeight: screenWidth >= 900 ? "21px" : undefined,
                      overflow: screenWidth < 900 ? "hidden" : undefined,
                      padding: screenWidth < 900 ? "20px 16px" : undefined,
                    }}
                  >
                    {screenWidth >= 900 && (
                      <>
                        <p className="span-wrapper-2">
                          <span className="text-wrapper-364">
                            During this period, you will be unable to place wagers, make deposits, or participate in
                            claiming rain and free cases. However, you will still have access to your account for
                            withdrawals and general site access.{" "}
                          </span>
                          <span className="text-wrapper-365">
                            Please note that once you choose to self-exclude, the decision is irreversible.{" "}
                          </span>

                          <span className="text-wrapper-364">
                            If you wish to request a permanent ban, please reach out to our support team for assistance.
                          </span>

                        </p>

                      </>
                    )}

                    {screenWidth < 900 && (
                      <>
                        <div className="text-wrapper-366">Withdrawn</div>
                        <div className="frame-625">
                          <img className="img-26" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-351">789</div>
                        </div>
                        <div className="div-wrapper-5">
                          <div className="overlap-group-109">

                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="frame-626"
                  style={{
                    alignItems: screenWidth >= 900 ? "flex-start" : screenWidth < 900 ? "center" : undefined,
                    alignSelf: screenWidth < 900 ? "stretch" : undefined,
                    display: screenWidth >= 900 ? "inline-flex" : screenWidth < 900 ? "flex" : undefined,
                    gap: screenWidth >= 900 ? "12px" : screenWidth < 900 ? "8px" : undefined,
                    width: screenWidth < 900 ? "100%" : undefined,
                  }}
                >
                  <div
                    className="frame-627"
                    style={{
                      background:
                        screenWidth < 900
                          ? "linear-gradient(180deg, rgb(27.7, 29.15, 30.6) 0%, rgba(28, 29, 31, 0.5) 100%)"
                          : undefined,
                      backgroundColor: screenWidth >= 900 ? "var(--frames)" : undefined,
                      flex: screenWidth < 900 ? "1" : undefined,
                      flexDirection: screenWidth < 900 ? "column" : undefined,
                      flexGrow: screenWidth < 900 ? "1" : undefined,
                      gap: screenWidth < 900 ? "8px" : undefined,
                      height: screenWidth < 900 ? "78px" : undefined,
                      justifyContent: screenWidth >= 900 ? "space-between" : screenWidth < 900 ? "center" : undefined,
                      padding: screenWidth >= 900 ? "12px 16px" : screenWidth < 900 ? "20px 16px" : undefined,
                      width: screenWidth >= 900 ? "170px" : undefined,
                    }}
                  >
                    {screenWidth >= 900 && (
                      <>
                        <div className="text-wrapper-367">7 Days</div>
                        <ChevronDownWrapper />
                      </>
                    )}

                    {screenWidth < 900 && (
                      <>
                        <div className="text-wrapper-368">Wagered</div>
                        <div className="frame-628">
                          <img className="img-26" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-349">35,5</div>
                        </div>
                        <div className="frame-629">
                          <div className="overlap-group-110">
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="frame-630"
                    style={{
                      background:
                        screenWidth < 900
                          ? "linear-gradient(180deg, rgb(27.7, 29.15, 30.6) 0%, rgba(28, 29, 31, 0.5) 100%)"
                          : undefined,
                      backgroundColor: screenWidth >= 900 ? "#ff363626" : undefined,
                      display: screenWidth >= 900 ? "inline-flex" : screenWidth < 900 ? "flex" : undefined,
                      flex: screenWidth >= 900 ? "0 0 auto" : screenWidth < 900 ? "1" : undefined,
                      flexDirection: screenWidth < 900 ? "column" : undefined,
                      flexGrow: screenWidth < 900 ? "1" : undefined,
                      height: screenWidth >= 900 ? "46px" : screenWidth < 900 ? "78px" : undefined,
                      padding: screenWidth >= 900 ? "12px 20px" : screenWidth < 900 ? "20px 16px" : undefined,
                    }}
                  >
                    {screenWidth >= 900 && (
                      <>
                        <div className="text-wrapper-369">Self-exclude</div>
                        <img className="img-26" alt="Vuesax bold lock" src="./Img/lock-self.svg" />
                      </>
                    )}

                    {screenWidth < 900 && (
                      <>
                        <div className="text-wrapper-368">Total</div>
                        <div className="frame-628">
                          <img className="img-26" alt="Storage" src="./Img/storage-green.svg" />
                          <p className="element-10">
                            <span className="text-wrapper-352">+</span>
                            <span className="text-wrapper-353">&nbsp;</span>
                            <span className="text-wrapper-352">789</span>
                          </p>
                        </div>
                        <div className="frame-631">
                          <div className="overlap-group-111">
                            <div className="rectangle-56" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
           

            {screenWidth >= 900 && (
              <div className="frame-632">
                <div className="text-wrapper-370">Log Out</div>
                <img className="img-26" alt="Vuesax linear logout" src="./Img/log-out.svg" />
              </div>
            )}

            {screenWidth < 900 && (
              <div className="frame-633">
                <div className="frame-634">
                  <a className="frame-635" href ="/settings">
                    <img className="img-27" alt="Vuesax bold setting" src="./img/settings.svg" />
                    <div className="text-wrapper-371">Settings</div>
                  </a>
                  <a className="frame-636" href="/game-history">
                    <div className="img-27">
                      <div className="gamepad-8" />
                    </div>
                    <div className="text-wrapper-372">Game history</div>
                  </a>
                  <a className="frame-637" href="/transactions">
                    <img className="img-27" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                    <div className="text-wrapper-372">Transactions</div>
                  </a>
                </div>
                <div className="frame-638">
                  <div className="frame-639">
                    <div className="frame-640">
                      <div className="frame-614">
                        <div className="text-wrapper-355">Steam ID</div>
                      </div>
                      <div className="frame-615">
                        <div className="frame-641">
                          <div className="text-wrapper-373">Your Steam ID</div>
                          <div className="text-wrapper-357">Get ID</div>
                        </div>
                        <div className="frame-617">
                          <div className="text-wrapper-358">Save</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-640">
                      <div className="frame-614">
                        <div className="text-wrapper-355">Trade link</div>
                      </div>
                      <div className="frame-615">
                        <div className="frame-616">
                          <p className="https-steamcommunity">
                            <a
                              href="https://steamcommunity.com/tradeoffer/new/?partner=1105743534&amp;token=lhflQ_6o"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <span className="text-wrapper-374">https://steamcommunity.</span>
                            </a>
                            <span className="text-wrapper-374">..</span>
                          </p>
                          <div className="text-wrapper-357">Change</div>
                        </div>
                        <div className="frame-617">
                          <div className="text-wrapper-358">Save</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-640">
                      <div className="frame-614">
                        <div className="text-wrapper-355">Discord</div>
                      </div>
                      <div className="frame-615">
                        <div className="frame-618">
                          <div className="text-wrapper-375">Not linked yet</div>
                        </div>
                        <div className="frame-619">
                          <img
                            className="img-26"
                            alt="Discord icon svgrepo"
                            src="./Img/discord.svg"
                          />
                          <div className="text-wrapper-358">Link discord</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-620">
                    <div className="frame-642">
                      <img className="closed-lock" alt="Closed lock" src="./Img/lock-self-exclusion.png" />
                      <div className="text-wrapper-360">Self-exclusion</div>
                    </div>
                    <div className="frame-643">
                      <div className="frame-597">
                        <p className="gambling-addiction-2">
                          <span className="text-wrapper-361">Gambling addiction is serious.</span>
                          <span className="text-wrapper-362">&nbsp;</span>
                          <span className="text-wrapper-363">
                            At Rust Oasis, we provide an option for you to temporarily self-exclude from our platform.
                          </span>
                        </p>
                        <p className="during-this-period-2">
                          <span className="text-wrapper-364">
                            During this period, you will be unable to place wagers, make deposits, or participate in
                            claiming rain and free cases. However, you will still have access to your account for
                            withdrawals and general site access.{" "}
                          </span>
                          <span className="text-wrapper-365">
                            Please note that once you choose to self-exclude, the decision is irreversible.{" "}
                          </span>
                          <span className="text-wrapper-364">
                            If you wish to request a permanent ban, please reach out to our support team for assistance.
                          </span>
                        </p>
                      </div>
                      <div className="frame-644">
                        <div className="frame-641">
                          <div className="text-wrapper-367">7 Days</div>
                        </div>
                        <div className="frame-645">
                          <div className="text-wrapper-369">Self-exclude</div>
                          <img className="img-26" alt="Vuesax bold lock" src="./Img/lock-self.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-646">
                    <div className="text-wrapper-370">Log Out</div>
                    <img className="img-26" alt="Vuesax linear logout" src="./Img/log-out.svg" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {screenWidth >= 900 && (
        <>
          <LiveChat />
        </>
      )}
    </div>
  );
};
