import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const PrivacyPolicy = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="privacy-policy">
      <div
        className="div-23"
        style={{
          height: screenWidth < 900 ? "852px" : screenWidth >= 900 ? "1080px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-89">
            <img className="vector-66" alt="Vector" src="./Img/vector_background.svg" />
            <div className="ellipse-119" />
            <div className="rectangle-75" />
            <div className="frame-848">
              <div className="frame-849">
                <div className="text-wrapper-497">Privacy policy</div>
                <div className="frame-850">
                  <p className="text-wrapper-498">Answers to your frequently asked questions</p>
                  <div className="frame-851">
                    <img
                      className="vuesax-linear-rotate-3"
                      alt="Vuesax linear rotate"
                      src="./Img/reload.svg"
                    />
                    <div className="text-wrapper-499">Updated February 5, 2024</div>
                  </div>
                </div>
              </div>
              <div className="frame-852">
                <div className="frame-853">
                  <a className="frame-854" href="privacy-policy">
                    <div className="text-wrapper-500">Privacy policy</div>
                  </a>
                  <a className="frame-855" href="/FAQ">
                    <div className="text-wrapper-499">FAQ</div>
                  </a>
                  <a className="frame-856" href="terms-of-service">
                    <div className="text-wrapper-501">Terms of service</div>
                  </a>
                </div>
                <div className="frame-857 scrollable-area-mobile">
                  <div className="frame-858">
                    <div className="text-wrapper-502">Information We Collect</div>
                    <div className="flexcontainer-2">
                      <p className="text-4">
                        <span className="text-wrapper-503">
                          We may collect the following types of information:
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Personal Identification Information: Name, email address, phone number, date of birth, and
                          payment information.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Technical Data: IP address, browser type, operating system, and device information.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Usage Data: Details about your gaming activity, preferences, and interactions with our
                          website.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-858">
                    <p className="text-wrapper-502">How We Use Your Information</p>
                    <div className="flexcontainer-2">
                      <p className="text-5">
                        <span className="text-wrapper-505">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          - To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          - To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          - To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          - To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">- To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-858">
                    <div className="text-wrapper-502">Introduction</div>
                    <p className="text-wrapper-507">
                      Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                      your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                      and safeguard your personal data.
                    </p>
                  </div>
                  <div className="frame-858">
                    <p className="text-wrapper-502">How We Use Your Information</p>
                    <div className="flexcontainer-2">
                      <p className="text-4">
                        <span className="text-wrapper-503">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          - To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          - To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          - To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          - To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">- To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="rectangle-76" />
          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-90">
              <img className="vector-68" alt="Vector" src="./Img/vector_background.svg" />
              <div className="ellipse-120" />
              <div className="frame-871">
                <div className="frame-872">
                  <div className="text-wrapper-514">Privacy policy</div>
                  <div className="frame-850">
                    <p className="text-wrapper-515">Set of rules for using the service</p>
                    <div className="frame-851">
                      <img
                        className="vuesax-linear-rotate-4"
                        alt="Vuesax linear rotate"
                        src="./Img/reload.svg"
                      />
                      <div className="text-wrapper-516">Updated February 5, 2024</div>
                    </div>
                  </div>
                </div>
                <div className="frame-873">
                  <div className="frame-874">
                    <a className="frame-875"  href="/privacy-policy">
                      <a className="text-wrapper-517">Privacy policy</a>
                    </a>
                    <a className="frame-855" href="/FAQ" >
                      <a className="text-wrapper-518" >FAQ</a>
                    </a>
                    <a className="frame-876"  href="/terms-of-service">
                      <a className="text-wrapper-518">Terms of service</a>
                    </a>
                  </div>
                  {/* text in the frame */}
                  <div className="frame-877 scrollable-area">
                    <div className="frame-858">
                      <div className="text-wrapper-519">Introduction</div>
                      <p className="div-24">
                        <span className="text-wrapper-520">
                          Welcome to [Casino Name]. We are committed to protecting your personal information and
                          ensuring your experience with us is safe and enjoyable.{" "}
                        </span>
                        <span className="text-wrapper-521">
                          This Privacy Policy outlines how we collect, use, and safeguard your personal data.
                        </span>
                      </p>
                    </div>
                    <div className="frame-858">
                      <div className="text-wrapper-519">Information We Collect</div>
                      <div className="flexcontainer-2">
                        <p className="div-24">
                          <span className="text-wrapper-522">
                            We may collect the following types of information:
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Personal Identification Information: Name, email address, phone number, date of birth, and
                            payment information.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Technical Data: IP address, browser type, operating system, and device information.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Usage Data: Details about your gaming activity, preferences, and interactions with our
                            website.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-858">
                      <p className="text-wrapper-519">How We Use Your Information</p>
                      <div className="flexcontainer-2">
                        <p className="text-6">
                          <span className="text-wrapper-523">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-858">
                      <div className="text-wrapper-519">Introduction</div>
                      <p className="text-wrapper-525">
                        Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                        your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                        and safeguard your personal data.
                      </p>
                    </div>
                    <div className="frame-858">
                      <p className="text-wrapper-519">How We Use Your Information</p>
                      <div className="flexcontainer-2">
                        <p className="div-24">
                          <span className="text-wrapper-522">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-66" />
            </div>
            <div className="side-bar-11">
              <img className="frame-886" />
              <div className="frame-887">
                <div className="text-wrapper-531">18+</div>
              </div>
            </div>
          <LiveChat/>
          </>
        )}
      </div>
    </div>
  );
};
