import React from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const LogoMobile = () => {
    const screenWidth = useWindowWidth();


    return (
        <>
            <div className="Logo-Mobile">
                <div className="frame-965">
                    <a href="/">
                    <img className="logo-40" alt="Logo" src="./Img/logo1.png" />
                    </a>
                    <div className="frame-939">
                        <div className="frame-967">
                            <div className="frame-968">
                                <div className="text-wrapper-573">Sign in</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};