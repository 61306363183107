import React from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";
import { LiveChat } from "../LiveChat/LiveChat";

export const LiveChatMobile = () => {
    const screenWidth = useWindowWidth();
    return (
        <div className="LiveChatMobile">
            <div className="div-chat"
            style={
                {
                    height: screenWidth < 900 ? "790px" : screenWidth >= 900 ? "1080px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
                }
            }>
                <div className="chat-12">
                    <div className="chat-header-12">
                        <div className="overlap-group-161">
                            <div className="ellipse-126" />
                            <div className="frame-919">
                                <img className="frame-920" alt="Frame" src="./Img/frame-1.png" />
                                <div className="text-wrapper-546">Live rain </div><div className="text-wrapper-second">Now</div>
                            </div>
                        </div>
                        <div className="frame-921">


                        </div>
                    </div>
                    <div className="overlap-97">
                        <div className="frame-923">


                        </div>
                        <div className="frame-930">
                            <div className="frame-931">
                                <input className="text-wrapper-555" type="text"
                                    placeholder="Your message..."
                                />

                                <img className="smile-12" alt="Smile" src="./Img/smile.svg" />
                                <button className="frame-932">
                                    <img className="frame-933" alt="Frame" src="./Img/frame-37.png" />
                                </button>

                            </div>

                        </div>



                    </div>

                </div>
                </div>
        </div>
    )
};