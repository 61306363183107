import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { VuesaxLinearTick } from "../../components/VuesaxLinearTick";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const UpgraderWaiting = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="upgrader-waiting">
      <div
        className="div-4"
        style={{
          height: screenWidth < 900 ? "1463px" : screenWidth >= 900 ? "1424px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        <div
          className="overlap-116"
          style={{
            height: screenWidth >= 900 ? "1472px" : screenWidth < 900 ? "1969px" : undefined,
            left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "0px" : undefined,
            position: screenWidth >= 900 ? "absolute" : screenWidth < 900 ? "relative" : undefined,
            top: screenWidth >= 900 ? "70px" : screenWidth < 900 ? "-217px" : undefined,
            width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "100%" : undefined,
          }}
        >

          {screenWidth < 900 && (
            <>
              <div className="group-4">
                <div className="ellipse-wrapper">
                  <div className="ellipse-5" />
                </div>
              </div>
              <div className="rectangle-7" />
              <div className="rectangle-8" />
              <div className="frame-49">

                <div className="text-wrapper-34">Upgrader</div>
                <div className="img-wrapper">
                  <img className="vuesax-linear-arrow-2" alt="Vuesax linear arrow" src="./Img/arrow-upgrader.svg" />
                </div>
              </div>
            </>
          )}

          <div
            className="frame-50"
            style={{
              gap: screenWidth >= 900 ? "48px" : screenWidth < 900 ? "32px" : undefined,
              left: screenWidth >= 900 ? "21px" : screenWidth < 900 ? "2%" : undefined,
              top: screenWidth >= 900 ? "564px" : screenWidth < 900 ? "392px" : undefined,
              width: screenWidth >= 900 ? "98% " : screenWidth < 900 ? "96%" : undefined,
            }}
          >
            <div
              className="frame-51"
              style={{
                alignItems: screenWidth < 900 ? "center" : screenWidth >= 900 ? "flex-start" : undefined,
                gap: screenWidth < 900 ? "28px" : screenWidth >= 900 ? "20px" : undefined,
              }}
            >
              <div
                className="group-5"
                style={{
                  alignItems: screenWidth >= 900 ? "center" : undefined,
                  display: screenWidth >= 900 ? "flex" : undefined,
                  flex: screenWidth >= 900 ? "0 0 auto" : undefined,
                  height: screenWidth < 900 ? "351px" : undefined,
                  justifyContent: screenWidth >= 900 ? "space-between" : undefined,
                  width: screenWidth < 900 ? "334px" : screenWidth >= 900 ? "100%" : undefined,
                }}
              >
                {screenWidth < 900 && (
                  <div className="overlap-group-6">
                    <div className="ellipse-6" />
                    <img className="ellipse-7" alt="Ellipse" src="./Img/circle_1.svg"  />
                    <img className="ellipse-8" alt="Ellipse" src="./Img/circle_2.svg"  />
                    <img className="ellipse-9" alt="Ellipse" src="./Img/circle_3.svg"  />
                    <div className="frame-52">
                      <div className="text-wrapper-35">Choose skin for upgrade</div>
                      <div className="frame-53">
                        <div className="text-wrapper-36">+</div>
                      </div>
                    </div>
                    <img className="polygon-3" alt="Barrigon" src="./Img/arrow-upgrader.svg" />
                  </div>
                )}

                {screenWidth >= 900 && (
                  <>
                    <div  className="frame-54">
                      <div className="frame-55">
                        <img
                          className="vuesax-linear-search"
                          alt="Vuesax linear search"
                          src="./Img/search.svg"
                        />
                        <div className="text-wrapper-37">Search</div>
                      </div>
                    </div>
                    <div className="frame-56">
                      <div className="frame-57">
                        <div className="text-wrapper-38">Price descending</div>
                        
                      </div>
                      <div className="frame-58">
                        <div className="text-wrapper-37">0.00</div>
                        <div className="frame-59">
                          <div className="overlap-group-7">
                            <img className="vector-3" alt="Vector" src="/img/vector-145-1.png" />
                            <div className="ellipse-10" />
                            <div className="ellipse-11" />
                          </div>
                        </div>
                        <div className="text-wrapper-39">200.00</div>
                      </div>
                      <div className="frame-60">
                        <div className="frame-61">
                          <div className="text-wrapper-40">0-5</div>
                        </div>
                        <div className="frame-61">
                          {/* <img className="storage-2" alt="Storage" src="./Img/storage-open-cases.svg" /> */}
                          <div className="text-wrapper-40">5-10</div>
                        </div>
                        <div className="frame-61">
                          <div className="text-wrapper-40">10-50</div>
                        </div>
                        <div className="frame-61">
                          <div className="text-wrapper-40">50-100</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="frame-64"
                style={{
                  alignItems: screenWidth < 900 ? "center" : screenWidth >= 900 ? "center" : undefined,
                  gap: screenWidth < 900 ? "32px" : screenWidth >= 900 ? "8px" : undefined,
                  justifyContent: screenWidth < 900 ? "center" : undefined,
                }}
              >
                {screenWidth < 900 && (
                  <div className="frame-65">
                    <div className="frame-66">
                      <div className="frame-67">
                        <div className="text-wrapper-42">Bet amount</div>
                        <div className="frame-68">
                          <div className="frame-69">
                            <img className="img-5" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-43">500</div>
                          </div>
                          <div className="frame-70">
                            <div className="frame-71">
                              <p className="element">
                                <span className="text-wrapper-44">1</span>
                                <span className="text-wrapper-45">/</span>
                                <span className="text-wrapper-44">2</span>
                              </p>
                            </div>
                            <div className="frame-71">
                              <div className="text-wrapper-46">X2</div>
                            </div>
                            <div className="frame-71">
                              <div className="text-wrapper-47">Max</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="overlap-group-8">
                          <img className="vector-4" alt="Vector" src="/img/vector-145-2.png" />
                          <img className="vector-5" alt="Vector" src="/img/vector-146-1.png" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-67">
                      <div className="text-wrapper-42">Chance</div>
                      <div className="frame-68">
                        <div className="frame-69">
                          <div className="storage-3">
                            <div className="text-wrapper-48">X</div>
                          </div>
                          <div className="text-wrapper-49">5</div>
                        </div>
                        <div className="frame-74">
                          <div className="frame-75">
                            <div className="text-wrapper-50">X 1,5</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-47">X2</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-47">X3</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-47">X5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {screenWidth >= 900 && (
                  <>
                    <div className="scrollable-area">
                      <div className="frame-77">
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-8">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-57">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-77">
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-77">
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-77">
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-4" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-77">
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-5" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-6" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-7" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-8" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-78">
                          <div className="overlap-group-9">
                            <div className="ellipse-13" />
                            <img className="image" alt="Image" src="./Img/object_1.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Resource</div>
                                <div className="text-wrapper-53">Cloth</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-9" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">65</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-82">
                          <div className="overlap-group-9">
                            <div className="ellipse-14" />
                            <img className="image" alt="Image" src="./Img/object_2.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Clothing</div>
                                <div className="text-wrapper-55">Snowcamo Jacket</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-10" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">200</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-83">
                          <div className="overlap-group-9">
                            <div className="ellipse-15" />
                            <img className="image" alt="Image" src="./Img/object_3.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Weapon</div>
                                <div className="text-wrapper-56">Tempered AK47</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-11" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">120</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-85">
                          <div className="overlap-group-9">
                            <div className="ellipse-16" />
                            <img className="image" alt="Image" src="./Img/object_4.png" />
                            <div className="frame-79">
                              <div className="frame-80">
                                <div className="text-wrapper-52">Misc</div>
                                <div className="text-wrapper-58">High Quality Bag</div>
                              </div>
                              <div className="frame-81">
                                <img className="storage-12" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-54">405</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {screenWidth < 900 && (
              <div className="frame-86">
                <div className="frame-87">
                  <div className="frame-88">
                    <div className="frame-89">
                      <div className="frame-90">
                        <img
                          className="vuesax-linear-search"
                          alt="Vuesax linear search"
                          src="./Img/search.svg"
                        />
                        <div className="text-wrapper-59">Search</div>
                      </div>
                    </div>
                    <div className="frame-91">
                      <div className="text-wrapper-60">Prise descending</div>
                    </div>
                  </div>
                  <div className="frame-92">
                    <div className="text-wrapper-59">0.00</div>
                    <div className="frame-59">
                      <div className="overlap-group-10">
                        <img className="vector-6" alt="Vector" src="/img/vector-145-3.png" />
                        <div className="ellipse-10" />
                        <div className="ellipse-17" />
                      </div>
                    </div>
                    <div className="text-wrapper-61">200.00</div>
                  </div>
                </div>
                <div className="frame-67">
                  <div className="frame-93">
                    <div className="frame-94">
                      <div className="frame-95">
                        <div className="overlap-group-11">
                          <div className="ellipse-18" />
                          <img className="image-3" alt="Image" src="./Img/object_1.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-62">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="./Img/object_2.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-94">
                      <div className="frame-98">
                        <div className="overlap-group-12">
                          <div className="ellipse-20" />
                          <img className="image-3" alt="Image" src="./Img/object_4.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-57">Misc</div>
                              <div className="text-wrapper-65">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                          <VuesaxLinearTick />
                        </div>
                      </div>
                      <div className="frame-99">
                        <div className="overlap-group-11">
                          <div className="ellipse-21" />
                          <img className="image-3" alt="Image" src="./Img/object_3.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-66">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-93">
                    <div className="frame-94">
                      <div className="frame-95">
                        <div className="overlap-group-11">
                          <div className="ellipse-18" />
                          <img className="image-3" alt="Image" src="./Img/object_1.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-62">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="./Img/object_2.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-94">
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="./Img/object_2.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-99">
                        <div className="overlap-group-11">
                          <div className="ellipse-21" />
                          <img className="image-3" alt="Image" src="./Img/object_3.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-66">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="./Img/storage.svg" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-94">
                    <div className="frame-100">
                      <div className="overlap-group-13">
                        <div className="ellipse-22" />
                        <img className="image-5" alt="Image" src="/img/image-3.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-14" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-13">
                        <div className="ellipse-23" />
                        <img className="image-5" alt="Image" src="/img/image-3-1.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-15" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-94">
                    <div className="frame-102">
                      <div className="overlap-group-14">
                        <div className="ellipse-24" />
                        <img className="image-5" alt="Image" src="/img/image-3-1.png" />
                        <div className="frame-103">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-16" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-100">
                      <div className="overlap-group-13">
                        <div className="ellipse-22" />
                        <img className="image-5" alt="Image" src="/img/image-3.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-17" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            )}
          </div>
          <div
            className="rectangle-9"
            style={{
              height: screenWidth >= 900 ? "241px" : screenWidth < 900 ? "167px" : undefined,
              left: screenWidth >= 900 ? "0" : screenWidth < 900 ? "0px" : undefined,
              top: screenWidth >= 900 ? "1113px" : screenWidth < 900 ? "1513px" : undefined,
              width: screenWidth >= 900 ? "80%" : screenWidth < 900 ? "100%" : undefined,
            }}
          />
        </div>
        {screenWidth >= 900 && (
          <>
            <div className="overlap-10">
              <img className="vector-7" alt="Vector" src="./Img/vector_background.svg" />
              <div className="ellipse-25" />
              <div className=" ">Upgrader</div>
              <div className="frame-113">
                <div className="frame-114">
                  <div className="text-wrapper-73">Choose sum to start</div>
                  <div className="frame-115">
                    <div className="frame-66">
                      <div className="frame-66">
                        <div className="text-wrapper-42">Bet amount</div>
                        <div className="frame-68">
                          <div className="frame-90">
                            <img className="img-6" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-39">500</div>
                          </div>
                          <div className="frame-70">
                            <button className="frame-71">
                              <p className="element">
                                <span className="text-wrapper-75">1/2</span>
                              </p>
                            </button>
                            <button className="frame-71">
                              <div className="text-wrapper-75">X2</div>
                            </button>
                            <button className="frame-71">
                              <div className="text-wrapper-75">Max</div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="overlap-group-15">
                          <img className="vector-8" alt="Vector" src="/img/vector-145.png" />
                          <img className="vector-5" alt="Vector" src="/img/vector-146.png" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-66">
                      <div className="text-wrapper-42">Chance</div>
                      <div className="frame-68">
                        <div className="frame-90">
                          <div className="img-6">
                            <div className="text-wrapper-76">X</div>
                          </div>
                          <div className="text-wrapper-37">5</div>
                        </div>
                        <div className="frame-74">
                          <div className="frame-75">
                            <p className="x-3">
                              <span className="text-wrapper-75">X 1,5</span>
                            </p>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-75">X2</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-75">X3</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-75">X5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-7">
                  <div className="overlap-11">
                    <div className="ellipse-26" />
                    <img className="ellipse-27" alt="Ellipse" src="./Img/circle_1.svg" />
                    <img className="ellipse-28" alt="Ellipse" src="./Img/circle_2.svg" />
                    <img className="ellipse-29" alt="Ellipse" src="./Img/circle_3.svg" />
                    <div className="frame-116">
                      <div className="frame-67">
                        <div className="text-wrapper-80">75%</div>
                        <div className="text-wrapper-81">Your chance to upgrade</div>
                      </div>
                      <div className="frame-117">
                        <div className="text-wrapper-82">Upgrade</div>
                      </div>
                    </div>
                    <img className="polygon-4" alt="Barrigon" src="./Img/arrow-upgrader.svg" />
                  </div>
                </div>
                <div className="frame-118">
                  <div className="frame-119">
                    <div className="text-wrapper-83">Skin for upgrade</div>
                    <div className="text-wrapper-84">Choose skin for upgrade</div>
                  </div>
                  <div className="frame-120">
                    <div className="overlap-group-16">
                      <div className="ellipse-30" />
                      <div className="frame-121">
                        <div className="text-wrapper-36">+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
