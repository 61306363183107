import React from "react";
import { SelectBox } from "../../components/SelectBox";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Img } from "../../components/Img";
import { Gb } from "../../components/Gb";
import "./style.css";
const dropDownOptions = [
    { label: "Case", value: "/case" },
    { label: "Case Battle", value: "/case-battle" },
    { label: "Upgrade", value: "/upgrade" },
    { label: "Roulette", value: "/roulette"}
];
export const Header = () => {
    const screenWidth = useWindowWidth();


    return (
        <div className="header">
            <div
                className="div-28"
                style={{
                    height: screenWidth < 900 ? "996px" : screenWidth >= 900 ? "70px" : undefined,
                    overflow: screenWidth < 900 ? "hidden" : undefined,
                    position: screenWidth < 900 ? "relative" : undefined,
                    width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "100%" : undefined,
                }}
            >
                {screenWidth >= 900 && (
                    <>
                        <header className="header-13">
                            <div className="overlap-group-169">
                                <div className="ellipse-134" />
                                {/* <div className="ellipse-134-line" /> */}
                                {/* <img className="line-40" alt="Line" src="/img/line-1.svg" /> */}
                            </div>
                            <div className="frame-969">
                                <div className="row-13">
                                    <img className="img-35" alt="Home" src="./Img/home.svg" />
                                    <a className="text-wrapper-574" href="/home-page-1">Home</a>
                                </div>
                            </div>
                            <div className="frame-970">

                                <a className="frame-972">
                                    <a className="text-wrapper-574">Sign in</a>
                                </a>
                            </div>
                            
                            <div className="frame-973">
                            <img className="h-[16px] w-[14px]" alt="Line" src="./Img/gameboy.svg" />
                                <SelectBox
                                    shape="round"
                                    indicator={<Img src="images/img_arrowdown.svg" alt="" className="h-[16px] w-[14px]" />}
                                    getOptionLabel={(e) => (
                                        <>
                                            <div className="flex items-center">
                                                <span href={e.value}>{e.label}</span>
                                            </div>
                                        </>
                                    )}
                                    name="grid"
                                    placeholder={`Games`}
                                    options={dropDownOptions}
                                    className="w-[38%] gap-1.5 font-semibold text-wrapper-574"
                                />
                               

                            </div>
                            <div className="frame-974">
                                <div className="row-13">
                                    <img className="img-35" alt="Home" src="./Img/daily-cases.svg" />
                                    <a className="text-wrapper-574" href="/daily-case">Daily cases</a>
                                </div>
                            </div>
                            <a href="/home-page-1">
                            <img className="logo-41" alt="Logo" src="../../Img/logo1.png" />
                            </a>
                        </header>
                    </>
                )}

            </div>
        </div>
    );
};
