import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const RouletteInGame = () => {
  const screenWidth = useWindowWidth();
  return (
    <div className="roulette-in-game">
      <div className="div-breakpoint"

        style={{
          height: screenWidth < 900 ? "1052px" : screenWidth >= 900 ? "1080px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth < 900 && (
          <>
            <div className="overlap-mobile">
              <img className="vector-background" alt="Vector" src="./Img/vector_background.svg" />
              <div className="header-title">
                <div className="Header">
                  <div className="title">Roulette</div>

                </div>
              </div>
              <div className="frame-recent-rolls">
                <div className="recent-rolls">
                  <div className="info-recent-rolls">
                    <p className="text-recent"> Recent Rolls
                    </p>
                    <img src="./Img/rencent-rolls.svg" alt=" recent rolls" className="img-1" />

                  </div>
                  <div className="info-recent-rolls">
                    <p className="text-recent"> Last 100 </p>
                    <img src="./Img/last-100.svg" alt=" recent rolls" className="img-1" />
                  </div>
                </div>
              </div>

              <div className="buttom-game">
                <a className="buttom-start">
                  <img src="./Img/storage.svg" alt="coins" className="img-2" />
                  <p className="text-buttom"> 789 </p>
                </a>
                <p className="text-buttom-center">In game</p>
              </div>
              <div className="roulette">
                <img className="polygon-4" alt="Barrigon" src="./Img/roulette-game.svg" />
              </div>
              <div className="settings">
                <div className="text-recent">Bet amount</div>
                <div className="components">
                  <div className="frame-90">
                    <img className="img-6" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-recent">500</div>
                  </div>
                  <div className="frame-70">
                    <a className="frame-71">
                      <p className="element">
                        <span className="text-recent">0,3</span>
                      </p>
                    </a>
                    <a className="frame-71">
                      <div className="text-recent">0,5</div>
                    </a>
                    <a className="frame-71">
                      <div className="text-recent">X2</div>
                    </a>
                    <a className="frame-71">
                      <div className="text-recent">Max</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="buttoms-settings">
                <div className="first-row">
                  <a className="buttom-fire">
                    <img src="./Img/fire.svg" alt="coins" className="img-2" />
                    <p className="text-buttom"> X2 </p>
                  </a>
                  <a className="buttom-tent">
                    <img src="./Img/camping.svg" alt="coins" className="img-2" />
                    <p className="text-buttom-tent"> X2 </p>
                  </a>
                </div>
                <div className="second-row">
                  <a className="buttom-tree">
                    <img src="./Img/tree.svg" alt="coins" className="img-2" />
                    <p className="text-buttom-tree"> X7 </p>
                  </a>
                  <a className="buttom-volcano">
                    <img src="./Img/nuclear.svg" alt="coins" className="img-2" />
                    <p className="text-buttom-volcano"> X14 </p>
                  </a>
                </div>
              </div>
              <div className="dropdown-buttoms">

                <div className="components-buttoms">
                  <div className="union">
                <img src="./Img/fire.svg" alt="coins" className="img-2" />
                <p className="text-buttom-tent"> Fire </p>
                <p className="text-buttom"> Orange </p>
                </div>
                <div className="union" >
                  <img src="./Img/storage.svg" alt="coins" className="img-2" />
                  <p className="text-buttom-tent"> 5890</p>
                </div>
                </div>
                <div className="components-buttoms">
                <div className="union">
                <img src="./Img/camping.svg" alt="coins" className="img-2" />
                <p className="text-buttom-tent"> Tent </p>
                <p className="text-buttom-tent"> Grey </p>
                </div>
                <div className="union" >
                  <img src="./Img/storage.svg" alt="coins" className="img-2" />
                  <p className="text-buttom-tent"> 5890</p>
                </div>
                </div>
                <div className="components-buttoms">
                <div className="union">
                <img src="./Img/tree.svg" alt="coins" className="img-2" />
                <p className="text-buttom-tent"> Tree </p>
                <p className="text-buttom-tree-2"> Green </p>
                </div>
                <div className="union" >
                  <img src="./Img/storage.svg" alt="coins" className="img-2" />
                  <p className="text-buttom-tent"> 5890</p>
                </div>
                </div>
                <div className="components-buttoms">
                <div className="union">
                <img src="./Img/nuclear.svg" alt="coins" className="img-2" />
                <p className="text-buttom-tent"> Volcano </p>
                <p className="text-buttom-volcano-2"> Red </p>
                </div>
                <div className="union" >
                  <img src="./Img/storage.svg" alt="coins" className="img-2" />
                  <p className="text-buttom-tent"> 5890</p>
                </div>
                </div>
              </div>
            </div>

          </>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap">

              <img className="vector" alt="Vector" src="./Img/vector_background.svg " />
              <div className="ellipse" />
              <div className="text-wrapper-2">Roulette</div>
              <div className="text-wrapper-3">In game</div>
              <div className="rectangle" />
              <div className="group">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <img className="img" alt="Frame" src="./Img/recent-round.png" />
                    <div className="group-2">
                      <img className="polygon" alt="Polygon" src="./Img/arrow-upgrader.svg" />
                      <img className="polygon-2" alt="Polygon" src="./img/arrow-upgrader.svg" />
                    </div>
                    <div className="rectangle-2" />
                    <div className="rectangle-3" />
                  </div>
                </div>
                <div className="frame-2">
                  <div className="frame-3">
                    <div className="text-wrapper-4">Recent Rolls</div>
                    <div className="frame-4">

                    </div>
                    <div className="rectangle-4" />
                  </div>
                  <div className="frame-5">
                    <img className="img-2" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-5">789</div>
                  </div>
                  <div className="frame-6">
                    <div className="text-wrapper-6">Last 100 rolls</div>
                    <div className="frame-7">
                      <div className="frame-8">
                        <img
                          className="img-3"
                          alt="Camping tent outdoor"
                          src="./Img/camping.svg"
                        />
                        <div className="text-wrapper-7">45</div>
                      </div>
                      <div className="frame-9">
                        <img className="img-3" alt="Iconex filled fire" src="./Img/fire.svg" />
                        <div className="text-wrapper-8">35</div>
                      </div>
                      <div className="frame-10">
                        <img className="img-3" alt="Vuesax bold tree" src="./Img/tree.svg" />
                        <div className="text-wrapper-9">10</div>
                      </div>
                      <div className="frame-11">
                        <img className="img-3" alt="Iconex filled fire" src="./Img/nuclear.svg" />
                        <div className="text-wrapper-10">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="vector-2" alt="Vector" src="/img/vector-151.png" />
              </div>
              <div className="frame-12">
                <div className="frame-wrapper">
                  <div className="frame-13">
                    <img className="img-4" alt="Iconex filled fire" src="./Img/fire.svg" />
                    <p className="x">
                      <span className="span">X</span>
                      <span className="text-wrapper-11">&nbsp;</span>
                      <span className="text-wrapper-12">2</span>
                    </p>
                  </div>
                </div>
                <div className="frame-14">
                  <div className="frame-15">
                    <div className="frame-7">
                      <img
                        className="img-4"
                        alt="Camping tent outdoor"
                        src="./Img/camping.svg"
                      />
                      <p className="p">
                        <span className="span">X</span>
                        <span className="text-wrapper-11">&nbsp;</span>
                        <span className="text-wrapper-12">2</span>
                      </p>
                    </div>

                  </div>
                </div>
                <div className="frame-16">
                  <div className="frame-13">
                    <img className="storage" alt="Storage" src="./Img/tree.svg" />
                    <p className="x-2">
                      <span className="span">X</span>
                      <span className="text-wrapper-11">&nbsp;</span>
                      <span className="text-wrapper-12">7</span>
                    </p>
                  </div>
                </div>
                <div className="frame-17">
                  <div className="frame-13">
                    <img className="img-4" alt="Iconex filled fire" src="./Img/nuclear.svg" />
                    <div className="text-wrapper-13">X 14</div>
                  </div>
                </div>
              </div>
              <div className="frame-18">
                <div className="frame-19">
                  <p className="div-2">
                    <span className="text-wrapper-14">Fire </span>
                    <span className="text-wrapper-15">Orange</span>
                  </p>
                  <div className="frame-20">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">5.689</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
              </div>
              <div className="frame-24">
                <div className="frame-19">
                  <p className="div-2">
                    <span className="text-wrapper-14">Tent </span>
                    <span className="text-wrapper-18">Grey</span>
                  </p>
                  <div className="frame-20">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">5.689</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-25">
                      <div className="frame-26">
                        <div className="text-wrapper-17">John Watson</div>
                        <div className="text-wrapper-19">You</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
                <div className="frame-21">
                  <div className="frame-7">
                    <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                    <div className="frame-22">
                      <div className="text-wrapper-17">John Watson</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <img className="storage" alt="Storage" src="./Img/storage.svg" />
                    <div className="text-wrapper-16">789</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-34">
              <div className="frame-19">
                <p className="div-2">
                  <span className="text-wrapper-14">Tree </span>
                  <span className="text-wrapper-24">Green</span>
                </p>
                <div className="frame-20">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">5.689</div>
                </div>
              </div>
              <div className="frame-21">
                <div className="frame-7">
                  <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                  </div>
                </div>
                <div className="frame-23">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">789</div>
                </div>
              </div>
              <div className="frame-21">
                <div className="frame-7">
                  <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                  </div>
                </div>
                <div className="frame-23">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">789</div>
                </div>
              </div>
            </div>
            <div className="frame-35">
              <div className="frame-19">
                <p className="div-2">
                  <span className="text-wrapper-14">Volcano </span>
                  <span className="text-wrapper-25">Red</span>
                </p>
                <div className="frame-20">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">5.689</div>
                </div>
              </div>
              <div className="frame-21">
                <div className="frame-7">
                  <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                  </div>
                </div>
                <div className="frame-23">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">789</div>
                </div>
              </div>
              <div className="frame-21">
                <div className="frame-7">
                  <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                  </div>
                </div>
                <div className="frame-23">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">789</div>
                </div>
              </div>
              <div className="frame-21">
                <div className="frame-7">
                  <img className="rectangle-5" alt="Rectangle" src="./Img/john_watson.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                  </div>
                </div>
                <div className="frame-23">
                  <img className="storage" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-16">789</div>
                </div>
              </div>
            </div>
            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
