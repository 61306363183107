import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const GameHistory = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="game-history">
      <div
        className="div-13 "
        style={{
          height: screenWidth < 900 ? "1200px" : screenWidth >= 900 ? "1080px" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth >= 900 && (
          <>
            <div className="frame-496">
              <div className="overlap-53">
                <img className="vector-36" alt="Vector" src="./Img/vector-settings.svg" />
                <div className="frame-497">
                  <div className="frame-498">
                    <div className="overlap-group-77">
                      <img className="rectangle-42" alt="Rectangle" src="./Img/john_watson.png" />
                      <div className="rectangle-43" />
                    </div>
                  </div>
                  <div className="frame-499">
                    <div className="frame-500">
                      <div className="text-wrapper-293">John Watson</div>
                      <p className="text-wrapper-294">Member since July 5 2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-501">
              <div className="frame-502">
                <a className="frame-503" href="/settings">
                  <img className="img-20" alt="Vuesax bold setting" src="./Img/settings-gray.svg" />
                  <div className="text-wrapper-295">Settings</div>
                </a>
                <a className="frame-504" href="/game-history">
                  <div className="img-20">
                    <div className="gamepad-5" />
                  </div>
                  <div className="text-wrapper-296">Game history</div>
                </a>
                <a className="frame-503" href="transactions">
                  <img className="img-20" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                  <div className="text-wrapper-295">Transactions</div>
                </a>
              </div>
              <div className="frame-505">
                <div className="frame-506">
                  <div className="text-wrapper-297">All</div>
                </div>
                <div className="frame-506">
                  <div className="text-wrapper-298">From latest</div>
                </div>
                <div className="frame-507">
                  <div className="frame-509">
                    <div className="text-wrapper-299">Roulette</div>
                  </div>
                  <div className="frame-509">
                    <div className="text-wrapper-299">Case</div>
                  </div>
                  <div className="frame-509">
                    <div className="text-wrapper-299">Case Battle</div>
                  </div>
                  <div className="frame-509">
                    <div className="text-wrapper-299">Upgrade</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-511">
              <div className="frame-512">
                <div className="vuesax-bold-card-wrapper">
                  <div className="vuesax-bold-card-4">
                    <div className="card-tick-4">
                    </div>
                  </div>
                </div>
                <div className="frame-513">
                  <div className="text-wrapper-300">Deposited</div>
                </div>
                <div className="frame-514">
                  <img className="img-22" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-301">789</div>
                </div>
              </div>

              <div className="frame-512">
                <div className="frame-515">
                  <div className="overlap-group-79">
                  </div>
                </div>
                <div className="text-wrapper-302">Withdrawn</div>
                <div className="frame-514">
                  <img className="img-22" alt="Storage" src="./Img/storage-green.svg" />
                  <div className="text-wrapper-303">789</div>
                </div>
              </div>

              <div className="frame-512">
                <div className="frame-517">
                  <div className="overlap-group-80">
                  </div>
                </div>
                <div className="text-wrapper-300">Wagered</div>
                <div className="frame-516">
                  <img className="img-22" alt="Storage" src="./Img/storage.svg" />
                  <div className="text-wrapper-301">35,5</div>
                </div>
              </div>

              <div className="frame-512">
                <div className="frame-518">
                  <div className="overlap-group-81">
                  </div>
                </div>
                <div className="text-wrapper-300">Total</div>
                <div className="frame-516">
                  <img className="img-22" alt="Storage" src="./Img/storage-green.svg" />
                  <p className="element-9">
                    <span className="text-wrapper-304">+</span>
                    <span className="text-wrapper-305">&nbsp;</span>
                    <span className="text-wrapper-304">789</span>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="overlap-54 "
          style={{
            height: screenWidth >= 900 ? "1023px" : screenWidth < 900 ? "1200px" : undefined,
            left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "0" : undefined,
            top: screenWidth >= 900 ? "70px" : screenWidth < 900 ? "45px" : undefined,
            width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "100%" : undefined,
          }}
        >
          <div
            className="rectangle-45"
            style={{
              alignItems: screenWidth < 900 ? "flex-start" : undefined,
              background:
                screenWidth >= 900
                  ? "linear-gradient(180deg, rgba(20, 21, 22, 0) 0%, rgb(20, 21, 22) 100%)"
                  : undefined,
              display: screenWidth < 900 ? "flex" : undefined,
              flexDirection: screenWidth < 900 ? "column" : undefined,
              gap: screenWidth < 900 ? "24px" : undefined,
              height: screenWidth >= 900 ? "241px" : undefined,
              left: screenWidth >= 900 ? "0" : screenWidth < 900 ? "2%" : undefined,
              top: screenWidth >= 900 ? "769px" : screenWidth < 900 ? "0" : undefined,
              width: screenWidth >= 900 ? "1506px" : screenWidth < 900 ? "96%" : undefined,
            }}
          >
            {screenWidth < 900 && (
              <>
                <div className="frame-521">
                  <div className="frame-522">
                    <div className="overlap-55">
                      <div className="ellipse-88" />
                      <div className="ellipse-89" />
                      <div className="ellipse-90" />
                      <div className="frame-523">
                        <div className="frame-498">
                          <div className="overlap-group-77">
                            <img className="rectangle-42" alt="Rectangle" src="./Img/john_watson.png" />
                            <div className="rectangle-43" />
                          </div>
                        </div>
                        <div className="frame-499">
                          <div className="frame-500">
                            <div className="text-wrapper-293">John Watson</div>
                            <p className="text-wrapper-294">Member since July 5 2022</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-14">
                    <div className="frame-524">
                      <div className="frame-525">
                        <div className="frame-513">
                          <div className="text-wrapper-300">Deposited</div>
                        </div>
                        <div className="frame-514">
                          <img className="img-21" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-301">789</div>
                        </div>
                        
                      </div>
                      <div className="frame-525">
                        <div className="text-wrapper-307">Withdrawn</div>
                        <div className="frame-526">
                          <img className="img-22" alt="Storage" src="./img/storage-green.svg" />
                          <div className="text-wrapper-303">789</div>
                        </div>
                        <div className="div-wrapper-4">
                          <div className="overlap-group-83">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-524">
                      <div className="frame-525">
                        <div className="text-wrapper-308">Wagered</div>
                        <div className="frame-527">
                          <img className="img-22" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-301">35,5</div>
                        </div>
                        <div className="frame-528">
                          <div className="overlap-group-84">
                          </div>
                        </div>
                      </div>
                      <div className="frame-525">
                        <div className="text-wrapper-308">Total</div>
                        <div className="frame-527">
                          <img className="img-22" alt="Storage" src="./Img/storage-gren.svg" />
                          <p className="element-9">
                            <span className="text-wrapper-304">+</span>
                            <span className="text-wrapper-305">&nbsp;</span>
                            <span className="text-wrapper-304">789</span>
                          </p>
                        </div>
                        <div className="frame-529">
                          <div className="overlap-group-85">
                            <div className="rectangle-46" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="line-20" alt="Line" src="/img/line-1-5.png" />
                <div className="frame-530">
                  <div className="frame-524">
                    <a className="frame-533" href="/settings">
                      <img className="img-23" alt="Vuesax bold setting" src="./Img/settings-gray.svg" />
                      <div className="text-wrapper-309">Settings</div>
                    </a>
                    <a className="frame-531" href="/game-history">
                      <div className="img-23">
                        <div className="gamepad-6" />
                      </div>
                      <div className="text-wrapper-310">Game history</div>
                    </a>
                    <a className="frame-533" href="/transactions">
                      <img className="img-23" alt="Vuesax bold receipt" src="./Img/transactions.svg" />
                      <div className="text-wrapper-310">Transactions</div>
                    </a>
                  </div>
                  <div className="frame-521">
                    <div className="div-14">
                      <div className="frame-534">
                        <div className="frame-535">
                          <div className="text-wrapper-311">All</div>
                        </div>
                        <div className="frame-535">
                          <div className="text-wrapper-312">From latest</div>
                        </div>
                      </div>
                      <div className="frame-536">
                        <div className="frame-538">
                          <div className="text-wrapper-314">Roulette</div>
                        </div>
                        <div className="frame-538">
                          <div className="text-wrapper-314">Case</div>
                        </div>
                        <div className="frame-538">
                          <div className="text-wrapper-314">Battle</div>
                        </div>
                        <div className="frame-538">
                          <div className="text-wrapper-314">Upgrade</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-521">
                      <div className="frame-540 scrollable-area-mobile">
                        <div className="frame-541">
                          <div className="frame-542">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="img-22" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-316">Roulette</div>
                              </div>
                              <div className="text-wrapper-312">20 sec.</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-547">
                            <img className="image-21" alt="Image" src="./Img/object_1.png" />
                            <img className="image-21" alt="Image" src="./Img/object_2.png" />
                            <img className="image-22" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-541">
                          <div className="frame-548">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="img-22" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-316">Case Battle</div>
                              </div>
                              <div className="text-wrapper-312">Today 20:40</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-541">
                          <div className="frame-542">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="img-22" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-316">Upgrade</div>
                              </div>
                              <div className="text-wrapper-312">20.05</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-547">
                            <img className="image-21" alt="Image" src="./Img/object_1.png" />
                            <img className="image-21" alt="Image" src="./Img/object_2.png" />
                            <img className="image-22" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-541">
                          <div className="frame-548">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="img-22" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-316">Case Battle</div>
                              </div>
                              <div className="text-wrapper-312">Today 20:40</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-541">
                          <div className="frame-542">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="img-22" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-316">Roulette</div>
                              </div>
                              <div className="text-wrapper-312">20 sec.</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-547">
                            <img className="image-22" alt="Image" src="./Img/object_1.png" />
                            <img className="image-22" alt="Image" src="./Img/object_2.png" />
                            <img className="image-22" alt="Image" src="./Img/object_3.png" />
                          </div>
                        </div>
                        <div className="frame-541">
                          <div className="frame-548">
                            <div className="frame-502">
                              <div className="frame-543">
                                <img className="frame-549" alt="Frame" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-316">Case Battle</div>
                              </div>
                              <div className="text-wrapper-312">Today 20:40</div>
                            </div>
                          </div>
                          <div className="frame-544">
                            <div className="frame-545">
                              <div className="text-wrapper-317">Bet</div>
                              <div className="frame-516">
                                <img className="storage-25" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-316">789</div>
                              </div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">X</div>
                              <div className="text-wrapper-319">14.5x</div>
                            </div>
                            <div className="frame-545">
                              <div className="text-wrapper-318">Result</div>
                              <div className="frame-546">
                                <img className="storage-26" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-316">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="frame-550"
            style={{
              alignItems: screenWidth >= 900 ? "flex-start" : undefined,
              background:
                screenWidth < 900
                  ? "linear-gradient(180deg, rgba(20, 21, 22, 0) 0%, rgb(20, 21, 22) 100%)"
                  : undefined,
              display: screenWidth >= 900 ? "flex" : undefined,
              flexDirection: screenWidth >= 900 ? "column" : undefined,
              gap: screenWidth >= 900 ? "32px" : undefined,
              height: screenWidth < 900 ? "167px" : undefined,
              left: screenWidth >= 900 ? "20px" : screenWidth < 900 ? "0" : undefined,
              top: screenWidth >= 900 ? "367px" : screenWidth < 900 ? "90%" : undefined,
              width: screenWidth >= 900 ? "1439px" : screenWidth < 900 ? "99%" : undefined,
            }}
          >
            {screenWidth >= 900 && (
              <div className="frame-551">
                <div className="scrollable-area">
                  <div className="group-39">

                    <div className="frame-552">
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-86">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-87">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-88">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-89">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-90">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-91">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-92">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-93">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-94">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-95">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-96">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-97">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>

                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-86">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-87">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-88">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-89">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-90">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-91">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-92">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-93">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-555">
                            <img className="group-40" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-320">Roulette</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-94">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-322">Case Battle</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-95">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                      <div className="frame-553">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/case.svg" />
                            <div className="text-wrapper-320">Case</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-96">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-321">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-559">
                        <div className="frame-554">
                          <div className="frame-560">
                            <img className="group-40" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-323">Upgrade</div>
                          </div>
                          <div className="frame-556">
                            <div className="group-40">
                              <div className="overlap-group-97">
                                <img className="star-35" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-36" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-320">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-557">
                          <div className="text-wrapper-297">20 sec ago</div>
                        </div>
                        <div className="frame-558">
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage.svg" />
                            <div className="text-wrapper-321">789</div>
                          </div>
                          <div className="text-wrapper-321">14.5x</div>
                          <div className="frame-516">
                            <img className="group-40" alt="Frame" src="./Img/storage-disabled.svg" />
                            <div className="text-wrapper-321">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-561">
                          <img className="image-21" alt="Image" src="./Img/object_1.png" />
                          <img className="image-21" alt="Image" src="./Img/object_2.png" />
                          <img className="image-22" alt="Image" src="./Img/object_3.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-41">
                    <div className="text-wrapper-324">Game</div>
                    <div className="text-wrapper-325">ITEM</div>
                    <div className="text-wrapper-326">Time</div>
                    <div className="text-wrapper-327">Bet</div>
                    <div className="text-wrapper-328">Multiplayer</div>
                    <div className="text-wrapper-329">Result</div>
                  </div>
                </div>

              </div>
            )}
          </div>
          <div
            className="rectangle-47"
            style={{
              background:
                screenWidth >= 900
                  ? "linear-gradient(180deg, rgba(20, 21, 22, 0) 0%, rgb(20, 21, 22) 100%)"
                  : undefined,
              backgroundColor: screenWidth < 900 ? "var(--frames)" : undefined,
              borderRadius: screenWidth < 900 ? "8px 8px 0px 0px" : undefined,
              boxShadow: screenWidth < 900 ? "0px 4px 50px #0000004c" : undefined,
              height: screenWidth >= 900 ? "241px" : screenWidth < 900 ? "87px" : undefined,
              top: screenWidth >= 900 ? "769px" : screenWidth < 900 ? "2000px" : undefined,
              width: screenWidth >= 900 ? "1506px" : screenWidth < 900 ? "100%" : undefined,
            }}
          >
          
          </div>
        </div>
        {screenWidth >= 900 && (
          <>
            <LiveChat />
          </>
        )}

       
      </div>
    </div>
  );
};
