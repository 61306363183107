import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import "./style.css";
import { LiveChat } from "../LiveChat/LiveChat";

export const TermsOfService = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="terms-of-service">
      <div
        className="div-21"
         style={{
          height: screenWidth < 900 ? "996px" : screenWidth >= 900 ? "1080px" : undefined,
          overflow: screenWidth < 900 ? "hidden" : undefined,
          position: screenWidth < 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-82">
            <img className="vector-63" alt="Vector" src="./Img/vector_background.svg" />
            <div className="ellipse-114" />
            <div className="rectangle-69" />
            <div className="frame-793">
              <div className="frame-794">
                <div className="text-wrapper-457">Terms of service</div>
                <div className="frame-795">
                  <p className="text-wrapper-458">Answers to your frequently asked questions</p>
                  <div className="frame-796">
                    <img
                      className="vuesax-linear-rotate"
                      alt="Vuesax linear rotate"
                      src="./Img/reload.svg"
                    />
                    <div className="text-wrapper-459">Updated February 5, 2024</div>
                  </div>
                </div>
              </div>

              <div className="frame-797">
                <div className="frame-798">
                  <a className="frame-799" href="/privacy-policy">
                    <div className="text-wrapper-459">Privacy policy</div>
                  </a>
                  <a className="frame-800" href="/FAQ">
                    <div className="text-wrapper-459">FAQ</div>
                  </a>
                  <a className="frame-801" href="/terms-of-service">
                    <div className="text-wrapper-460">Terms of service</div>
                  </a>
                </div>
                <div className="frame-802 scrollable-area-mobile">
                  <div className="frame-803">
                    <p className="text-wrapper-461">How We Use Your Information</p>
                    <div className="flexcontainer">
                      <p className="text">
                        <span className="text-wrapper-462">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="text">
                        <span className="text-wrapper-463">
                          - To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="text">
                        <span className="text-wrapper-463">
                          - To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="text">
                        <span className="text-wrapper-463">
                          - To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="text">
                        <span className="text-wrapper-463">
                          - To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="text">
                        <span className="text-wrapper-463">- To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-803">
                    <div className="text-wrapper-461">Introduction</div>
                    <p className="text-wrapper-464">
                      Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                      your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                      and safeguard your personal data.
                    </p>
                  </div>
                  <div className="frame-803">
                    <p className="text-wrapper-461">How We Use Your Information</p>
                    <div className="flexcontainer">
                      <p className="span-wrapper">
                        <span className="text-wrapper-465">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">- To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-803">
                    <div className="text-wrapper-461">Introduction</div>
                    <p className="text-wrapper-464">
                      Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                      your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                      and safeguard your personal data.
                    </p>
                  </div>
                  <div className="frame-803">
                    <p className="text-wrapper-461">How We Use Your Information</p>
                    <div className="flexcontainer">
                      <p className="span-wrapper">
                        <span className="text-wrapper-465">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">
                          - To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-466">- To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-83">
              <img className="vector-65" alt="Vector" src="./Img/vector_background.svg" />
              
              <div className="frame-816">
                <div className="frame-817">
                  <div className="text-wrapper-473">Terms of service</div>
                  <div className="frame-795">
                    <p className="text-wrapper-474">Set of rules for using the service</p>
                    <div className="frame-796">
                      <img
                        className="vuesax-linear-rotate-2"
                        alt="Vuesax linear rotate"
                        src="./Img/reload.svg"
                      />
                      <div className="text-wrapper-475">Updated May 3, 2023</div>
                    </div>
                  </div>
                </div>
                <div className="frame-818">
                  <div className="frame-819">
                  <button className="frame-820">
                      <a className="text-wrapper-476" href="/privacy-policy">Privacy policy</a>
                    </button>
                    <button className="frame-820">
                      <a className="text-wrapper-476" href="/FAQ">FAQ</a>
                    </button>
                    <button className="frame-822">
                      <a className="text-wrapper-477" href="/terms-of-service ">Terms of service</a>
                    </button>
                    
                  </div>
                  <div className="frame-823 scrollable-area">
                    <div className="frame-803">
                      <div className="text-wrapper-478">Information We Collect</div>
                      <div className="flexcontainer">
                        <p className="text-2">
                          <span className="text-wrapper-479">
                            We may collect the following types of information:
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · Personal Identification Information: Name, email address, phone number, date of birth, and
                            payment information.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · Technical Data: IP address, browser type, operating system, and device information.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · Usage Data: Details about your gaming activity, preferences, and interactions with our
                            website.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-803">
                      <p className="text-wrapper-478">How We Use Your Information</p>
                      <div className="flexcontainer">
                        <p className="text-3">
                          <span className="text-wrapper-481">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="text-3">
                          <span className="text-wrapper-482">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="text-3">
                          <span className="text-wrapper-482">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="text-3">
                          <span className="text-wrapper-482">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="text-3">
                          <span className="text-wrapper-482">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="text-3">
                          <span className="text-wrapper-482">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-803">
                      <div className="text-wrapper-478">Introduction</div>
                      <p className="text-wrapper-483">
                        Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                        your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                        and safeguard your personal data.
                      </p>
                    </div>
                    <div className="frame-803">
                      <p className="text-wrapper-478">How We Use Your Information</p>
                      <div className="flexcontainer">
                        <p className="text-2">
                          <span className="text-wrapper-479">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-480">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
                <div className="rectangle-66" />
              </div>
              
            </div>
            <div className="side-bar-10">
              <img className="frame-832" />
              <div className="frame-833">
                <div className="text-wrapper-489">18+</div>
              </div>
            </div>
            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
