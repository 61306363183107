import React, { useState } from "react";
import { useWindowWidth } from "../../breakpoints";
import { LiveChat } from "../LiveChat/LiveChat";
import { Footer } from "../Footer";
import "./style.css";

const accordionData = [{
  title: 'How do I create an account?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
    laborum cupiditate possimus labore, hic temporibus velit dicta earum
    suscipit commodi eum enim atque at? Et perspiciatis dolore iure
    voluptatem.`,
  value: 1,
},
{
  title: 'How can I withdraw my winnings?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
  laborum cupiditate possimus labore, hic temporibus velit dicta earum
  suscipit commodi eum enim atque at? Et perspiciatis dolore iure
  voluptatem.`,
  value: 2,
},
{
  title: 'How do I create an account?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
  laborum cupiditate possimus labore, hic temporibus velit dicta earum
  suscipit commodi eum enim atque at? Et perspiciatis dolore iure
  voluptatem.`,
  value: 3,
},
{
  title: 'What payment methods do you accept?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
  laborum cupiditate possimus labore, hic temporibus velit dicta earum
  suscipit commodi eum enim atque at? Et perspiciatis dolore iure
  voluptatem.`,
  value: 4,
},
{
  title: 'How can I withdraw my winnings?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
  laborum cupiditate possimus labore, hic temporibus velit dicta earum
  suscipit commodi eum enim atque at? Et perspiciatis dolore iure
  voluptatem.`,
  value: 5,
},
{
  title: 'How do I create an account?',
  content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
  laborum cupiditate possimus labore, hic temporibus velit dicta earum
  suscipit commodi eum enim atque at? Et perspiciatis dolore iure
  voluptatem.`,
  value: 6,
},
];

const { title, content, value } = accordionData;

export const Faq = () => {
  const screenWidth = useWindowWidth();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="FAQ">
      <div className="div-26"
        style={{
          height: screenWidth < 900 ? "996px" : screenWidth >= 900 ? "1080px" : undefined,
          overflow: screenWidth < 900 ? "hidden" : undefined,
          position: screenWidth < 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth >= 900 && (
          <>

            <div className="overlap-96">

              <div className="frame-902">
                <div className="text-wrapper-539">FAQ</div>
                <div className="frame-903">
                  <p className="text-wrapper-540">Answers to your frequently asked questions</p>
                  <div className="frame-904">
                    <img
                      className="vuesax-linear-rotate-5"
                      alt="Vuesax linear rotate"
                      src="./Img/reload.svg"
                    />
                    <div className="text-wrapper-541">Updated February 5, 2024</div>
                  </div>
                </div>
              </div>
              <React.Fragment>
                <div className="frame-905 scrollable-area">


                  {accordionData.map(({ title, content, value }) => (
                    <>
                      <div className="frame-906" onClick={() => setIsActive(!isActive)} >

                        <div className="text-wrapper-542">
                          <div>{title}</div>

                        </div>

                      </div>
                      {isActive && <div className="accordion-content">{content}</div>}
                    </>
                  ))}
                  
                </div>
                <div className="rectangle-66" />
              </React.Fragment>

              <LiveChat />
            </div>

          </>
        )
        }
        {screenWidth < 900 && (
          <>
          <div className="overlap-mobile">
            <img className="vector-70" src="./Img/vector_background.svg" />
            <div className="frame-header-mobile">
              <div className="text-wrapper-header"> FAQ</div>
                <div className="frame-sub-header">
                  <p className="text-wrapper-sub-header">Answers to your frequently asked questions </p>
                  <div className="frame-reload">
                  <img
                      className="vuesax-linear-rotate-5"
                      alt="Vuesax linear rotate"
                      src="./Img/reload.svg"
                    />
                    <div className="text-wrapper-reload">Updated February 5, 2024</div>
                  </div>
                </div>
            </div>
            <React.Fragment>
                <div className="frame-FAQ scrollable-area-mobile">


                  {accordionData.map(({ title, content, value }) => (
                    <>
                      <div className="frame-906" onClick={() => setIsActive(!isActive)} >

                        <div className="text-wrapper-FAQ">
                          <div>{title}</div>

                        </div>

                      </div>
                      {isActive && <div className="accordion-content">{content}</div>}
                    </>
                  ))}
                  
                </div>
                <div className="rectangle-66" />
              </React.Fragment>
          </div>
          </>
        )}
      </div>
    </div >
  );
};
