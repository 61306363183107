import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const CaseBattles = () => {
    const screenWidth = useWindowWidth();
    return (
        <div className="CaseBattles">
            <div
                className="div-principal"
                style={{
                    height: screenWidth < 900 ? "1463px" : screenWidth >= 900 ? "1424px" : undefined,
                    position: screenWidth >= 900 ? "relative" : undefined,
                    width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "100%" : undefined,
                }}
            >
                <div
                    className="overlap-principal"
                    style={{
                        height: screenWidth >= 900 ? "1472px" : screenWidth < 900 ? "1969px" : undefined,
                        left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "-22px" : undefined,
                        position: screenWidth >= 900 ? "absolute" : screenWidth < 900 ? "relative" : undefined,
                        width: screenWidth >= 900 ? "100%" : screenWidth < 900 ? "418px" : undefined,
                    }}
                >

                    {screenWidth >= 900 && (
                        <div className="header-component" >
                            <img className="vector-header" src="./Img/vector_background.svg" alt="" />
                            <div className="text-header">Case Battles </div>
                            <div className="filters-header">
                                <div className="title-filter">
                                    <h3 className="text-title-filter"> Case Battles </h3>
                                    <p className="text-undertitle">20 Ative Battles · </p><p className="text-green">3 Joinable</p>
                                </div>
                                <div className="buttom-filter">
                                    <div className="select-filter">
                                        <div className="text-select-filter">By Price</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <LiveChat />
            </div>
        </div>
    );
};