import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import "./style.css";
import { LiveChat } from "../LiveChat/LiveChat";


export const Bets = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="bets">
      <div
        className="div-19"
        style={{
          height: screenWidth < 900 ? "1000px" : screenWidth >= 900 ? "1080px" : undefined,
          overflow: screenWidth >= 900 ? "hidden" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "100%" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-73">
            <div className="ellipse-107" />
            <div className="rectangle-60" />
            <div className="frame-702">
              <div className="frame-703">
                <div className="element-11">
                  <div className="frame-712">
                    <div className="frame-713">
                      <div className="frame-714">
                        <div className="text-wrapper-413">Deposited</div>
                      </div>
                      <div className="div-wrapper-6">
                        <div className="vuesax-bold-card-9">
                          <div className="card-tick-8">
                            <img className="vector-55" alt="Vector" src="./Img/storage.svg" />
                          </div>
                        </div>
                      </div>
                      <div className="frame-715">
                        <img className="img-28" alt="Storage" src="./Img/storage.svg" />
                        <div className="text-wrapper-414"> 789 </div>
                      </div>
                      
                    </div>
                    <div className="frame-713">
                      <div className="text-wrapper-415">Withdrawn</div>
                      <div className="frame-716">
                        <img className="img-29" alt="Storage" src="./img/storage-green.svg" />
                        <div className="text-wrapper-416"> 789 </div>
                      </div>
                      <div className="div-wrapper-6">
                        <div className="overlap-group-119">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-712">
                    <div className="frame-713">
                      <div className="text-wrapper-417">Wagered</div>
                      <div className="frame-717">
                        <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                        <div className="text-wrapper-414">35,5</div>
                      </div>
                      <div className="frame-718">
                        <div className="overlap-group-120">

                        </div>
                      </div>
                    </div>
                    <div className="frame-713">
                      <div className="text-wrapper-417">Total</div>
                      <div className="frame-717">
                        <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                        <p className="element-12">
                          <span className="text-wrapper-418"> + </span>
                          <span className="text-wrapper-419">&nbsp;</span>
                          <span className="text-wrapper-418"> 789 </span>
                        </p>
                      </div>
                      <div className="frame-719">
                        <div className="overlap-group-121">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-720">
                <div className="frame-721">
                  <div className="frame-722">
                    <div className="frame-723">
                      <div className="text-wrapper-420">All</div>
                    </div>
                    <div className="frame-723">
                      <div className="text-wrapper-421">From latest</div>
                    </div>
                  </div>
                  <div className="frame-724">
                    <div className="frame-726">
                      <div className="text-wrapper-423"> Roulette </div>
                    </div>
                    <div className="frame-726">
                      <div className="text-wrapper-423"> Case </div>
                    </div>
                    <div className="frame-726">
                      <div className="text-wrapper-423">Battle</div>
                    </div>
                    <div className="frame-726">
                      <div className="text-wrapper-423"> Upgrade </div>
                    </div>
                  </div>
                </div>
                <div className="frame-728">
                  <div className="frame-729">
                    <div className="text-wrapper-425">Bets</div>
                  </div>
                  <div className="frame-730 scrollable-area-mobile">
                  <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-411"> Roulette </div>
                          </div>
                          <div className="text-wrapper-421">20 sec.</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle </div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411">789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-411"> Upgrader </div>
                          </div>
                          <div className="text-wrapper-421">20.05</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle </div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-411"> Roulette </div>
                          </div>
                          <div className="text-wrapper-421">20 sec.</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle</div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789</div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-411"> Roulette </div>
                          </div>
                          <div className="text-wrapper-421">20 sec.</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle </div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411">789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/upgrade.svg" />
                            <div className="text-wrapper-411"> Upgrader </div>
                          </div>
                          <div className="text-wrapper-421">20.05</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle </div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-732">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/roulette.svg" />
                            <div className="text-wrapper-411"> Roulette </div>
                          </div>
                          <div className="text-wrapper-421">20 sec.</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789 </div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-736">
                        <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                        <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                      </div>
                    </div>
                    <div className="frame-731">
                      <div className="frame-737">
                        <div className="frame-706">
                          <div className="frame-708">
                            <img className="img-29" alt="Frame" src="./Img/case-battle.svg" />
                            <div className="text-wrapper-411"> Case Battle</div>
                          </div>
                          <div className="text-wrapper-421">Today 20:40</div>
                        </div>
                      </div>
                      <div className="frame-733">
                        <div className="frame-734">
                          <div className="text-wrapper-426">Bet</div>
                          <div className="frame-707">
                            <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-411"> 789</div>
                          </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">X</div>
                          <div className="text-wrapper-428"> 14.5x </div>
                        </div>
                        <div className="frame-734">
                          <div className="text-wrapper-427">Result</div>
                          <div className="frame-735">
                            <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                            <div className="text-wrapper-411"> + 789  </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div className="rectangle-62" />
          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-75 scrollable-area">


              <div className="rectangle-64" />
              <div className="frame-745">
                <div className="frame-746">
                  {/* card 1 hover on svg to shine */}
                  <div className="frame-747">
                    <div className="vuesax-bold-card-10">
                      <div className="vuesax-bold-card-11">
                        <div className="card-tick-9" />
                      </div>
                    </div>
                    <div className="frame-714">
                      <div className="text-wrapper-413">Deposited</div>
                    </div>
                    <div className="frame-715">
                      <img className="img-28" alt="Storage" src="./Img/storage.svg" />
                      <div className="text-wrapper-414"> 789</div>
                    </div>

                  </div>
                  {/* end card */}
                  <div className="frame-799">
                    <div className="text-wrapper-432">Withdrawn</div>
                    <div className="frame-715">
                      <img className="img-30" alt="Storage" src="./Img/storage-green.svg " />
                      <div className="text-wrapper-416"> 789</div>
                    </div>
                    <div className="frame-748">
                      <div className="overlap-group-123" />

                    </div>
                    <div className="ellipse-110" />
                  </div>
                  <div className="frame-747">
                    <div className="text-wrapper-413">Wagered</div>
                    <div className="frame-707">
                      <img className="img-29" alt="Storage" src="./Img/storage.svg " />
                      <div className="text-wrapper-414">35,5</div>
                    </div>
                    <div className="frame-749">
                      <div className="overlap-group-124" />
                    </div>
                  </div>
                  <div className="frame-799">
                    <div className="frame-750">
                      <div className="overlap-group-125">
                      </div>
                    </div>
                    <div className="text-wrapper-413">Total</div>
                    <div className="frame-707">
                      <img className="img-29" alt="Storage" src="./Img/storage-green.svg " />
                      <p className="element-12">
                        <span className="text-wrapper-418"> </span>
                        <span className="text-wrapper-419">&nbsp;</span>
                        <span className="text-wrapper-418">789 </span>
                      </p>
                    </div>

                  </div>
                </div>

                <div className="frame-751">
                  <div className="frame-752">
                    <div className="text-wrapper-433">Bets</div>
                  </div>
                  <div className="frame-753">
                    <div className="frame-754">
                      <div className="text-wrapper-434">All</div>

                    </div>
                    <div className="frame-754">
                      <div className="text-wrapper-435">From latest</div>

                    </div>
                    <div className="frame-755">
                      <div className="frame-757">
                        <div className="text-wrapper-437">Roulette</div>
                      </div>
                      <div className="frame-757">
                        <div className="text-wrapper-437">Case</div>
                      </div>
                      <div className="frame-757">
                        <div className="text-wrapper-437"> Case Battle</div>
                      </div>
                      <div className="frame-757">
                        <div className="text-wrapper-437">Upgrade</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="frame-759">
                  <div className="frame-760">
                    <div className="scrollable-area">
                      <div className="group-49">

                        <div className="frame-761">
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-126">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-127">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case.svg" />
                                <div className="text-wrapper-438">Case</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-128">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-441">Upgrade</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-129">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-130">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-131">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case.svg" />
                                <div className="text-wrapper-438">Case</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-132">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-441">Upgrade</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-133">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-134">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-135">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case.svg" />
                                <div className="text-wrapper-438">Case</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-136">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-441">Upgrade</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-137">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-138">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-139">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-134">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-135">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case.svg" />
                                <div className="text-wrapper-438">Case</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-136">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-441">Upgrade</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-137">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-138">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-139">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-134">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-135">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case.svg" />
                                <div className="text-wrapper-438">Case</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-136">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/upgrade.svg" />
                                <div className="text-wrapper-441">Upgrade</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-137">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                          <div className="frame-762">
                            <div className="frame-763">
                              <div className="frame-729">
                                <img className="group-50" alt="Frame" src="./Img/roulette.svg" />
                                <div className="text-wrapper-438">Roulette</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-138">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-green.svg" />
                                <div className="text-wrapper-439">+ 789</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-767">
                            <div className="frame-763">
                              <div className="frame-768">
                                <img className="group-50" alt="Frame" src="./Img/case-battle.svg" />
                                <div className="text-wrapper-440">Case Battle</div>
                              </div>
                              <div className="frame-764">
                                <div className="group-50">
                                  <div className="overlap-group-139">
                                    <img className="star-49" alt="Star" src="/img/star-1-7.png" />
                                    <img className="star-50" alt="Star" src="/img/chevron-down-1.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-438">ツ HXNRY 1X ツ</div>
                              </div>
                            </div>
                            <div className="frame-765">
                              <div className="text-wrapper-434">20 sec ago</div>
                            </div>
                            <div className="frame-766">
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage.svg" />
                                <div className="text-wrapper-439">789</div>
                              </div>
                              <div className="text-wrapper-439">14.5x</div>
                              <div className="frame-707">
                                <img className="img-29" alt="Storage" src="./Img/storage-disabled.svg" />
                                <div className="text-wrapper-439">+ 0</div>
                              </div>
                            </div>
                            <div className="frame-769">
                              <img className="group-50" alt="Frame" src="./Img/object_1.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_2.png" />
                              <img className="group-50" alt="Frame" src="./Img/object_3.png" />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="group-51">
                      <div className="text-wrapper-442">Game</div>
                      <div className="text-wrapper-443">ITEM</div>
                      <div className="text-wrapper-444">Time</div>
                      <div className="text-wrapper-445">Bet</div>
                      <div className="text-wrapper-446">Multiplayer</div>
                      <div className="text-wrapper-447">Result</div>
                    </div>
                  </div>
                </div>

                <div className="rectangle-66" />
              </div>
            </div>
            <div className="side-bar-9">
              {/* OJO esto no es una imagen es una serie de botones en vertical , estudia como hacerlo  */}

              <img className="frame-743" />
              <div className="frame-744">
                <div className="text-wrapper-431">18 </div>
              </div>
            </div>

            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
